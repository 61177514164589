import { TExceptionTrack, TPageViewTrack, TEventTrack } from '../types';
import { appInsights } from './appInsights';

const developer = {
  exception: ({ id, error, properties }: TExceptionTrack): void => {
    appInsights.trackException(
      {
        id,
        exception: error || new Error(id),
      },
      properties,
    );
  },
  pageView: ({ path, isLoggedIn }: TPageViewTrack): void => {
    appInsights.trackPageView({
      uri: path,
      isLoggedIn,
      name: `Page is loaded`,
    });
  },
  event: ({ name, properties }: TEventTrack): void => {
    appInsights.trackEvent({ name }, properties);
  },
};

export default developer;
