import React, { useState, useEffect } from 'react';
import { Grid } from '@ingeniorforeningen/figurine-core';
import { Select } from '../inputs';
import { TComponentProps, TSelectOption } from '../../types';
import { formatLocalToSelect } from '../../data/utilities';
import situationsData from '../../data/local/situations.json';
import { sortAlphabetically } from '../../utilities';

const Situation: React.FC<TComponentProps> = ({ area }) => {
  const [situations, setSituations] = useState([] as TSelectOption[]);

  useEffect(() => {
    const situationsWithJobs = Object.entries(situationsData).filter(([, val]) => val.hasJob);

    const sortedSituationsWithJobs = sortAlphabetically(
      formatLocalToSelect(Object.fromEntries(situationsWithJobs)),
    );
    setSituations(sortedSituationsWithJobs);
  }, []);

  return (
    <Grid container columns={12} spacing="xl" mb="lg">
      <Grid item xs={12} sm={6} lg={4}>
        <Select field="situationId" area={area} options={situations} required />
      </Grid>
    </Grid>
  );
};

export default Situation;
