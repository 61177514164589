import { TSelectFieldItem } from '@ingeniorforeningen/figurine-core';
import { useQuery } from '@tanstack/react-query';
import { uiDataClient } from './clients';

export const useCompanies = (query: string) =>
  useQuery({
    queryKey: ['companies', query],
    queryFn: async () => uiDataClient.getCompanies(query.toLowerCase()),
    staleTime: Infinity,
    select: (data): TSelectFieldItem[] =>
      data &&
      data.map((company) => ({
        value: company.id!,
        label: company.companyName!,
        description: company.address,
      })),
  });
