import { Consent } from '@ingeniorforeningen/member-signup-backend-client';
import { Aka2DTO } from '@ida/membersignup-client';
import React, { useState } from 'react';
import {
  Button,
  Grid,
  Group,
  Html,
  Paragraph,
  TextField,
  useLocaleText,
} from '@ingeniorforeningen/figurine-core';
import { service } from 'src/data/clients';
import { useConsent } from 'src/data/consent';
import { getErrorMessage, getFieldText, getParagraphText } from '../../../data/helpers';
import track from '../../../tracking';
import useUmbracoStore from '../../../store/umbraco/umbracoStore';
import useMemberStore from '../../../store/member/memberStore';
import { createModalTriggerFromRichtext, formatCpr } from '../../../utilities';
import { useStyles } from './styles';

type TProps = {
  updateStatus: (status: string) => void;
};

// The state isn't filled at this point with user information
// Therefor any use of the state would be useless.
// This means we also can't use the inputs from the /inputs folder
// because they are essentially inputs wrapped in a state handler.

const AkSignupForm: React.FC<TProps> = ({ updateStatus }) => {
  const { useCprNo } = useMemberStore((state) => state.member.personal);
  const [error, setError] = useState<boolean>(false);
  const [previousUnemploymentFund, setPreviousUnemploymentFund] = useState<string>();
  const [cprNumberAkSignup, setCprNumberAkSignup] = useState<string>('');
  const [hasOtherAkasse, setHasOtherAkasse] = useState<boolean | null>(null);

  const akaInfo = useUmbracoStore((state) => state.akaInfo);
  const { leadId, languageId } = useMemberStore.getState().member.config;
  const { data: cprConsentInfo } = useConsent('cpr');
  const language = languageId?.toLowerCase();
  const texts = useLocaleText();
  const { classes } = useStyles();

  // Temporary addition to pinpoint what state the user has when it hits an error on this page
  const member = useMemberStore((state) => state.member);

  const consentText = cprConsentInfo
    ? `<p><b>${cprConsentInfo?.title}</b><br />${cprConsentInfo?.text} ${createModalTriggerFromRichtext(
        cprConsentInfo?.additionalText as string,
        getParagraphText('readMore'),
      )}</p>`
    : '';

  const requiresCprNumber = Boolean(!useCprNo && cprConsentInfo?.version);

  const isButtonDisabled = () => {
    if (requiresCprNumber) {
      return cprNumberAkSignup?.length !== 11 || hasOtherAkasse === null;
    }
    return hasOtherAkasse === null;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const signup = new Aka2DTO({
      unemploymentFund: previousUnemploymentFund,
      leadId,
    });

    if (requiresCprNumber) {
      signup.cprNo = cprNumberAkSignup;
      signup.cprConsent = new Consent({
        version: cprConsentInfo?.version?.full,
        allowed: true,
      });
    }
    service
      .signupAka_new(signup)
      .then(() => {
        setError(false);
        updateStatus('done');

        track.forms.completed({ name: 'akasse' });
      })
      .catch(() => {
        track.exception({
          errorcode: 500,
          id: 'Aka signup could not be submitted',
          properties: { leadId, member },
        });
        setError(true);
      });
  };

  return (
    <form name="akasse" onSubmit={handleSubmit}>
      <Grid container>
        <Group justify="center">
          <Button
            id="hasOtherAkasseYes"
            variant={hasOtherAkasse ? 'default' : 'outline'}
            fullWidth
            type="button"
            onClick={() => {
              setHasOtherAkasse(true);
              updateStatus('inProgress');
            }}
            text={texts.yes}
          />

          <Button
            id="hasOtherAkasseNo"
            variant={hasOtherAkasse === false ? 'default' : 'outline'}
            fullWidth
            onClick={() => {
              setHasOtherAkasse(false);
              updateStatus('inProgress');
            }}
            text={texts.no}
          />
        </Group>
        {hasOtherAkasse && (
          <Grid item mt="md">
            <TextField
              name="previousAkasseName"
              label={getFieldText('previousAkasseName').label}
              placeholder={getFieldText('previousAkasseName').placeholder}
              description={getFieldText('previousAkasseName').helpText}
              value={previousUnemploymentFund}
              className={classes.paperFriendlyInput}
              onChange={(e) => setPreviousUnemploymentFund(e.currentTarget.value)}
              required
            />
          </Grid>
        )}

        <Grid item>
          {requiresCprNumber && (
            <>
              <Paragraph text={getFieldText('akasseCpr').label} />
              <TextField
                name="cprNo"
                label={getFieldText('cprNo').label}
                placeholder={getFieldText('akasseCpr').placeholder}
                value={formatCpr(cprNumberAkSignup)}
                onChange={(e) => setCprNumberAkSignup(e.currentTarget.value)}
                maxLength={11}
                className={classes.paperFriendlyInput}
                required
              />
              {error && <Paragraph text={getErrorMessage('notCpr')} variant="error" />}
              <Html html={consentText} />
            </>
          )}
          <Paragraph text={getParagraphText('aKasseYourDataInfo')} variant="neutral" />
          <Button
            id="submitAka"
            disabled={isButtonDisabled()}
            variant="cta"
            type="submit"
            text={akaInfo.ctaText[language]}
          />
        </Grid>

        {error &&
          (cprNumberAkSignup ? (
            <Grid item>
              <Paragraph text={getErrorMessage('isInvalid')} variant="error" />
            </Grid>
          ) : (
            <Grid item>
              <Paragraph text={getErrorMessage('somethingWentWrong')} variant="error" />
            </Grid>
          ))}
      </Grid>
    </form>
  );
};

export default AkSignupForm;
