/* eslint-disable react/jsx-wrap-multilines */
import {
  Grid,
  Heading,
  Modal,
  Paper,
  Paragraph,
  Group,
  List,
  ModalButton,
} from '@ingeniorforeningen/figurine-core';
import React, { useState } from 'react';
import shallow from 'zustand/shallow';
import { getFieldText, getParagraphText } from '../../data/helpers';
import months from '../../data/local/months.json';
import useMemberStore from '../../store/member/memberStore';

const Membership = () => {
  const { language, isStudent } = useMemberStore(
    (state) => ({
      language: state.member.config.languageId.toLowerCase(),
      isStudent: state.member.education.isStudent,
    }),
    shallow,
  );
  const [showModal, setShowModal] = useState(false);
  const today = new Date();
  const date = `${today.getDate()}. ${
    months[language as keyof typeof months][today.getMonth()]
  } ${today.getFullYear()}`;
  const paymentText = isStudent ? getParagraphText('paymentStudent') : getParagraphText('payment');
  const disclaimer = isStudent
    ? getParagraphText('disclaimerStudent')
    : getParagraphText('disclaimer');

  return (
    <Group>
      <Grid container columns={12}>
        <Grid item xs={12} lg={8}>
          <Paper variant="secondary" p="xl">
            <Group direction="column">
              <Paragraph text={getParagraphText('membership')} variant="light" />
              <Heading type="h1" text={date} variant="light" />
              <Paragraph text={paymentText} variant="light" />
            </Group>
          </Paper>
          <Group>
            <Paragraph text={disclaimer} variant="neutral" />
            <Modal
              onClose={() => setShowModal(false)}
              title={getParagraphText('priceHeader')}
              opened={showModal}
              closeButtonLabel="Ok"
            >
              <Group p="xl">
                <List
                  items={[
                    { text: getParagraphText('pricesStudent') },
                    { text: getParagraphText('pricesEmployed') },
                    { text: getParagraphText('pricesPensioner') },
                  ]}
                />
                <Paragraph text={getParagraphText('priceBody')} />
              </Group>
            </Modal>
            <ModalButton
              mt="xl"
              mb="xl"
              buttonText={getFieldText('buttonPrices').label}
              title={getParagraphText('priceHeader')}
              modalContent={
                <Group p="xl">
                  <List
                    items={[
                      { text: getParagraphText('pricesStudent') },
                      { text: getParagraphText('pricesEmployed') },
                      { text: getParagraphText('pricesPensioner') },
                    ]}
                  />
                  <Paragraph text={getParagraphText('priceBody')} />
                </Group>
              }
            />
          </Group>
        </Grid>
      </Grid>
    </Group>
  );
};

export default Membership;
