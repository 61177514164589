import { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';
import { backendClient, service } from 'src/data/clients';
import { Signup, Situation, StudentSignup } from '@ingeniorforeningen/member-signup-backend-client';
import { getErrorMessage } from '../data/helpers';
import useMemberStore from '../store/member/memberStore';
import { TAreaName, TMemberAreas } from '../store/member/types';
import useUiStore from '../store/ui/uiStore';
import track from '../tracking';
import useUmbracoStore from '../store/umbraco/umbracoStore';

export const useValidation = (): { valid: boolean; errors: { [field: string]: string } } => {
  const member = useMemberStore((state) => state.member);
  const steps = useUmbracoStore((state) => state.stepNodes);
  const { area, currentStep, updateUi } = useUiStore(
    (state) => ({
      area: state.area,
      currentStep: state.currentStep,
      updateUi: state.updateUi,
    }),
    shallow,
  );
  const [stepValid, setStepValid] = useState(false);
  const [inputs, setInputs] = useState<NodeListOf<Element>>();
  const [errors, setErrors] = useState({});
  const [totalSteps, setTotalSteps] = useState<number>();

  const fetchInputs = () => {
    // We use a timer here because the state is slow and the component is slowly insert into the DOM
    setTimeout(() => {
      const foundInputs = document.querySelectorAll('#root input');
      setInputs(foundInputs);
    }, 200);
  };

  useEffect(() => {
    if (steps) {
      setTotalSteps(steps.length);
    }
  }, [steps]);

  useEffect(() => {
    setStepValid(false);
    // We fetch inputs in the DOM everytime we currentStep updates or a checkbox is checked.
    fetchInputs();
  }, [currentStep, member]);

  useEffect(() => {
    setStepValid(false);
    if (member.job.situationId === Situation.Student) {
      backendClient.validateStudentSignUp(member as StudentSignup)
    };
  

    service
      .validateSignupInformation(member as Signup)
      .then(() => {
        setErrors({});
        updateUi('errors', {});
        setStepValid(true);
      })
      .catch((validationErrors) => {
        if (validationErrors.model) {
          // TODO: Add user feedback here
          track.exception({
            errorcode: 500,
            id: 'A corrupted validation model is recieved from membersignup-client',
            error: validationErrors,
          });
        } else if (validationErrors.message === 'Network Error') {
          // TODO: Add user feedback here
          track.exception({
            errorcode: 500,
            id: 'A network error occured during validation',
            error: validationErrors,
          });
        } else if (currentStep === totalSteps) {
          // Data review
          // TODO: Move to method, currently not possible due to validationError is of type any
          const errorsList: { [field: string]: string } = {};
          const areasWithErrors = Object.keys(validationErrors);

          areasWithErrors.forEach((validationArea: string) => {
            const propertiesWithErrors = Object.keys(validationErrors[validationArea as string]);
            propertiesWithErrors.forEach((property: string) => {
              const errorMessage = validationErrors[validationArea as string][property as string];
              errorsList[property] = errorMessage;
            });
          });
          track.exception({
            errorcode: 400,
            id: 'Data review page is invalid due to validation errors',
            error: validationErrors,
            properties: { errorsList },
          });

          setErrors(errorsList);
          updateUi('errors', errorsList);
        } else if (inputs) {
          // Step with inputs
          // TODO: Move to method, currently not possible due to validationError is of type any
          const errorsList: { [field: string]: string } = {};
          const statusOfInputs: string[] = [];
          // For each input found in the DOM we want to check if a validation error is found for it.
          inputs.forEach((input) => {
            const fieldName = input.getAttribute('name')?.toLocaleLowerCase() || '';

            if (validationErrors[area as string]) {
              if (validationErrors[area as string][fieldName]) {
                errorsList[fieldName] = validationErrors[area as string][fieldName];
                statusOfInputs.push('invalid');
              } else {
                statusOfInputs.push('valid');
              }
            }
          });

          const anInputIsInvalid = statusOfInputs.indexOf('invalid') > -1;
          setStepValid(!anInputIsInvalid);

          if (member.education.useOtherSpecialization) {
            setStepValid(false);
          }

          setErrors(errorsList);
          updateUi('errors', errorsList);
        }
      });
  }, [inputs]);

  return { valid: stepValid, errors };
};

export const useFieldValidation = (
  field: string,
  area: TAreaName,
  disabled = false,
): { valid: boolean; message: string; showError: boolean } => {
  const value = useMemberStore((state) => state.member[area][field as keyof TMemberAreas]);
  const errorType = useUiStore((state) => state.errors[field.toLowerCase()]);

  const [hasInteracted, setHasInteracted] = useState(false);
  const [valid, setValid] = useState(false);

  const handleBlur = () => {
    setHasInteracted(true);
  };

  useEffect(() => {
    const input = document.querySelectorAll(`input[name='${field}']`)[0];

    if (input) {
      input.addEventListener('blur', handleBlur);
    }

    return () => input?.removeEventListener('blur', handleBlur);
  }, []);

  useEffect(() => {
    if (value) {
      setValid(Boolean(!errorType));
      if (!errorType) {
        setHasInteracted(true);
      }
    }

    if (!value && hasInteracted) {
      setValid(false);
    }
  }, [value, errorType]);

  return {
    valid,
    message: getErrorMessage(errorType as string),
    showError: hasInteracted && !valid && !disabled,
  };
};
