/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import AccessDenied from './AccessDenied';
import { AuthConsumer } from './AuthProvider';

interface PrivateRouteProps {
  roles?: string[];
  element: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, roles }) => (
  <AuthConsumer>
    {({ isAuthenticated, signinRedirect, hasRole }): JSX.Element => {
      if (!!element && isAuthenticated()) {
        if (roles && roles.length) {
          if (!roles.some((roleName) => hasRole(roleName))) {
            return <AccessDenied roles={roles} />;
          }
        }
        return element;
      }
      signinRedirect();
      return <div />;
    }}
  </AuthConsumer>
);

export default PrivateRoute;
