import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: runtimeConfig.appInsightsKey,
    extensions: [reactPlugin],
    disableAjaxTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    enableAutoRouteTracking: true,
  },
});
export { reactPlugin, appInsights };
