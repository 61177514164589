import { Config, Consent, IConfig } from "@ingeniorforeningen/member-signup-backend-client";

export type IConfigExtended = IConfig;

export class ConfigExtended extends Config {
  constructor(data: IConfigExtended) {
    super(data);
    this.cprConsent = new Consent(data.cprConsent);
    this.marketingConsent = new Consent(data.marketingConsent);
  }
}