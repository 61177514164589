import React from 'react';
import { AuthConsumer } from './AuthProvider';

const Logout: React.FC = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout();
      return <div />;
    }}
  </AuthConsumer>
);

export default Logout;
