import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import shallow from 'zustand/shallow';
import { Container } from '@ingeniorforeningen/figurine-core';
import { TLocalData } from '../../data/types';
import { TStepProps } from '../../types';
import Job from '../inputGroups/Job';
import Situation from '../inputGroups/Situation';
import situationsData from '../../data/local/situations.json';
import { getParagraphText } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';
import useUiStore from '../../store/ui/uiStore';

const Employment: React.FC<TStepProps> = ({ area }) => {
  const updateUi = useUiStore((state) => state.updateUi);
  const { situationId } = useMemberStore(
    (state) => ({
      situationId: state.member.job.situationId,
    }),
    shallow,
  );
  const [chosenSituation, setChosenSituation] = useState<{ [key: string]: unknown }>({});
  useEffect(() => {
    updateUi('area', area);
  }, []);

  useEffect(() => {
    setChosenSituation((situationsData as TLocalData)[situationId]);
  });

  return (
    <Container>
      <Helmet>
        <title>{getParagraphText('employment')}</title>
      </Helmet>
      <Situation area={area} />
      {chosenSituation?.hasJob ? <Job area={area} /> : undefined}
    </Container>
  );
};

export default Employment;
