import { Heading, Loader, Paragraph, Group } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import track from '../../tracking';

const Error: React.FC = () => {
  track.exception({
    errorcode: 500,
    id: 'error page is shown',
    properties: {
      pathname: window.location.pathname,
    },
  });

  return (
    <Group>
      <Loader size="xl" />
      <Heading type="h2" align="center" text="Der skete en fejl" />
      <Paragraph
        align="center"
        text="  Vi prøver at løse problemet. Siden vil opdatere om 5 sekunder."
      />
    </Group>
  );
};

export default Error;
