import { Situation } from '@ingeniorforeningen/member-signup-backend-client';
import useMemberStore from '../store/member/memberStore';
import { TUmbracoGiftsForm, TUmbracoNode, TUmbracoTemplates } from '../store/umbraco/types';
import { TSelectOption } from '../types';
import { TLocalData } from './types';
import { UiStep } from '../store/ui/types';
import { convertUiStepToUmbracoStepNumber } from '../store/umbraco/helpers';

const isGiftNodeEmpty = (nodes: TUmbracoNode[]) => {
  const giftNode = nodes.find(
    (node: TUmbracoNode) => node.template === TUmbracoTemplates.membersignupFormGifts,
  ) as TUmbracoGiftsForm;
  const controlsLocation = giftNode?.gridGift?.sections[0].rows[0].areas[0].controls;
  const multiboxNode = controlsLocation?.find((x) => x.editor.alias === 'multiBox');

  if (multiboxNode && typeof multiboxNode.value !== 'string') {
    if (multiboxNode.value.items && multiboxNode.value.items.length > 0) {
      return false;
    }
  }

  return true;
};

export const formatServiceToSelect = (data: unknown): TSelectOption[] => {
  const result: TSelectOption[] = [];
  const typedData = data as Record<string, unknown>;
  if (data) {
    Object.keys(typedData).forEach((key) => {
      const obj = typedData[key] as Record<string, unknown>;
      let keyIsId = false;
      let label = '';
      let value = '';

      // The key is the id
      if (key.length === 36 && key.match(/-/g)?.length === 4) {
        keyIsId = true;
        value = key;
      }

      if (!keyIsId) {
        if (obj.id) {
          value = obj.id as string;
        } else if (obj.countryId) {
          value = obj.countryId as string;
        } else if (obj.levelId) {
          value = obj.levelId as string;
        }
      }
      if (obj.name) {
        label = obj.name as string;
      } else if (obj.displayName) {
        label = obj.displayName as string;
      }

      result.push({
        label,
        value,
        description: (obj.description as string) || undefined,
      });
    });
  }

  return result;
};

export const formatLocalToSelect = (data: TLocalData): TSelectOption[] => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const result: TSelectOption[] = [];

  Object.keys(data).forEach((key) => {
    result.push({
      label: data[key][language] as string,
      value: key,
    });
  });

  return result;
};

export const localizeProp = (propName: string): string => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  if (language === 'en') {
    return `${propName}English`;
  }

  return propName;
};

export const localizePropTo = (propName: string, lang: string): string => {
  if (lang.toLowerCase() === 'en') {
    return `${propName}English`;
  }

  return propName;
};

export const isOnCorrectLangUrl = (lang: string): boolean => {
  const urlLang = window.location.pathname.split('/').filter((x) => x)[0] === 'en' ? 'en' : 'da';
  if (urlLang !== lang.toLowerCase()) {
    return false;
  }
  return true;
};

export const filterByLanguage = (nodes: TUmbracoNode[], givenLang?: string): TUmbracoNode[] => {
  let lang = useMemberStore.getState().member.config.languageId.toLowerCase();

  if (givenLang) {
    lang = givenLang;
  }

  if (lang === 'da') {
    return nodes.filter(
      (node: TUmbracoNode) => !(node.slug as string).includes('/en/') && node.slug !== '/en',
    );
  }
  return nodes.filter((node: TUmbracoNode) => (node.slug as string).includes('/en/'));
};

export const readyUmbracoStepsForForm = (
  situation: Situation,
  nodes?: TUmbracoNode[],
): TUmbracoNode[] => {
  const skipEmployment = situation === Situation.Student || situation === Situation.Unemployed;
  const skipGifts = situation !== Situation.Student;

  if (nodes) {
    let steps: TUmbracoNode[] = nodes.filter(
      (node: TUmbracoNode) =>
        node.template === TUmbracoTemplates.membersignupFormStep ||
        node.template === TUmbracoTemplates.membersignupFormGifts,
    );

    steps = filterByLanguage(steps).sort(
      (a, b) => (a.stepNumber as number) - (b.stepNumber as number),
    );

    if (skipEmployment) {
      steps = steps.filter(
        (node: TUmbracoNode) =>
          node.stepNumber !== convertUiStepToUmbracoStepNumber(UiStep.employment),
      );
    }

    const noGifts = isGiftNodeEmpty(steps);

    if (skipGifts || noGifts) {
      steps = steps.filter(
        (node: TUmbracoNode) => node.template !== TUmbracoTemplates.membersignupFormGifts,
      );
    }

    return steps;
  }

  return [] as TUmbracoNode[];
};

export const extractFormWrapper = (nodes?: TUmbracoNode[]): TUmbracoNode => {
  const result = nodes?.find(
    (node: TUmbracoNode) => node.template === TUmbracoTemplates.membersignupForm,
  );
  return result as TUmbracoNode;
};

export const extractReciepts = (nodes: TUmbracoNode[]): TUmbracoNode[] =>
  filterByLanguage(
    nodes.filter(
      (node: TUmbracoNode) => node.template === TUmbracoTemplates.membersignupRecieptPage,
    ),
  );
