import { Education, IEducation } from "@ingeniorforeningen/member-signup-backend-client";

export interface IEducationExtended extends IEducation{
  institutionName: string;
  levelName: string;
  specializationName: string;
  endMonthName: string;
  isStudent: boolean;
  isPhd: boolean;
  useAbroadEducation: boolean;
  useOtherSpecialization: boolean;
  educationEndsSoon: boolean;
}

export class EducationExtended extends Education {
  institutionName: string;

  levelName: string;

  specializationName: string;

  endMonthName: string;

  isStudent: boolean;

  isPhd: boolean;

  useAbroadEducation: boolean;

  useOtherSpecialization: boolean;

  educationEndsSoon: boolean;

  constructor(data: IEducationExtended) {
    super(data);
    this.institutionName = data.institutionName;
    this.levelName = data.levelName;
    this.specializationName = data.specializationName;
    this.endMonthName = data.endMonthName;
    this.isStudent = data.isStudent;
    this.isPhd = data.isPhd;
    this.useAbroadEducation = data.useAbroadEducation;
    this.useOtherSpecialization = data.useOtherSpecialization;
    this.educationEndsSoon = data.educationEndsSoon;
  }
}
