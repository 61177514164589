import { Grid } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import { TComponentProps } from '../../types';
import { TextField } from '../inputs';

const BasicInfo = ({ area }: TComponentProps): JSX.Element => (
  <>
    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="firstName" area={area} required />
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="lastName" area={area} required />
    </Grid>

    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="email" area={area} required />
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="phone" area={area} required />
    </Grid>
    
    <Grid item xs={12} lg={8}> </Grid>
  </>
);

export default BasicInfo;
