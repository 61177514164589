import { UiDataClient } from '@ingeniorforeningen/uidata-client';
import { DeliveryApiClientWrapper } from '@ingeniorforeningen/umbraco-client';
import { QueryClient } from '@tanstack/react-query';
import { Client as OldMemberSignupClient } from '@ida/membersignup-client';
import { ConsentExternalApiClient } from '@ingeniorforeningen/consent-client';
import { Client as MemberSignupBackendClient } from '@ingeniorforeningen/member-signup-backend-client';
import { axiosBaseApiClient, axiosBaseApiClientWithoutTransform } from './axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const uiDataClient = new UiDataClient(
  runtimeConfig.uiDataClientId,
  axiosBaseApiClientWithoutTransform,
);

export const backendClient = new MemberSignupBackendClient(
  runtimeConfig.memberSignupBackendClientId,
  axiosBaseApiClient,
);
export const service = new OldMemberSignupClient(runtimeConfig.serviceDomain, axiosBaseApiClient);

export const consentClient = new ConsentExternalApiClient(
  runtimeConfig.consentClientId,
  axiosBaseApiClient,
);

export const umbracoClientOld = axiosBaseApiClient.get(runtimeConfig.umbracoDomain || '');

export const umbracoClient = new DeliveryApiClientWrapper(
  runtimeConfig.umbraco,
  axiosBaseApiClient,
);
