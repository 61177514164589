import { Grid } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import ReviewContent from './ReviewContent';

const DataReview = (): JSX.Element => (
  <Grid container>
    <Grid item>
      <ReviewContent areaName="gift" />
      <ReviewContent areaName="personal" />
      <ReviewContent areaName="education" />
      <ReviewContent areaName="job" />
    </Grid>
  </Grid>
);

export default DataReview;
