import {
  ManagerialResponsibility,
  Situation,
} from '@ingeniorforeningen/member-signup-backend-client';

const empty = 'ingen værdi';

export const convertSituationToTitle = (situation: Situation): string => {
  switch (situation) {
    case Situation.Unemployed:
      return 'ledig';
    case Situation.Student:
      return 'studerende';
    case Situation.Unknown:
    case Situation.Employed:
      return 'i arbejde';
    default:
      return empty;
  }
};

export const convertStepToTitle = (step = empty): string => {
  const stepTitle = step?.toLowerCase();
  switch (stepTitle) {
    case 'uddannelse':
    case 'education':
      return 'uddannelse';
    case 'oplysninger om dig':
    case 'information about you':
      return 'oplysninger om dig';
    case 'beskæftigelse':
    case 'job':
      return 'beskæftigelse';
    case 'bekræft':
    case 'confirm':
      return 'bekræft';
    default:
      return empty;
  }
};

export const convertPositionToTitle = (position?: ManagerialResponsibility): string => {
  switch (position) {
    case ManagerialResponsibility.NoManagerialResponsibility:
      return 'jeg har ikke ledelsesansvar';
    case ManagerialResponsibility.ManagerialResponsibility:
      return 'jeg er leder af medarbejdere';
    case ManagerialResponsibility.Projectleader:
      return 'jeg er projektleder';
    default:
      return empty;
  }
};
