import React, { ReactNode, useEffect } from 'react';
import { Location } from 'react-router-dom';
import { AuthConsumer } from '../components/authentication/AuthProvider';
import track from '../tracking';
import { TUmbracoNode } from '../store/umbraco/types';

type TProps = {
  location: Location;
  node: TUmbracoNode;
  children: ReactNode;
};

const RouteWrapper: React.FC<TProps> = ({ children, node, location }) => {
  useEffect(() => {
    track.pageView({
      path: location.pathname as string,
      nodeId: node.nodeId as string,
      isLoggedIn: false,
      pageTitle: node.header as string,
    });
  }, [location]);

  if (node.private) {
    return (
      <AuthConsumer>
        {({ isAuthenticated, signinRedirect }) => {
          if (isAuthenticated()) {
            return <div>{children}</div>;
          }
          signinRedirect();
          return <div />;
        }}
      </AuthConsumer>
    );
  }

  return <div>{children}</div>;
};

export default RouteWrapper;
