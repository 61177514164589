import { Grid, Group, Heading, Paragraph } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import { getExtendedField, getFieldText, getParagraphText } from '../../data/helpers';
import { SignupExtended } from '../../store/member/areas/SignupExtended';
import { memberInitialState } from '../../store/member/initialState';
import useMemberStore from '../../store/member/memberStore';
import { formatCpr, formatSituation } from '../../utilities';

type TComponentProps = {
  areaName: keyof SignupExtended;
};

const formatValue = (value: unknown, field: string, lang: string): string => {
  if (value && typeof value === 'object') {
    return `${(value as Date).getDay()}/${(value as Date).getMonth()}/${(
      value as Date
    ).getFullYear()}`;
  }
  if (field === 'cprNo') {
    return formatCpr(value as string);
  }

  if (field === 'situationName') {
    return formatSituation(value as string, lang);
  }
  if (field === 'selectedGift') {
    return (value as string) === '-' ? getParagraphText('noGiftSelected') : (value as string);
  }
  return value as string;
};

const ReviewContent = ({ areaName }: TComponentProps) => {
  const content = useMemberStore.getState().member[areaName];
  const initialState = memberInitialState[areaName];

  const fields = Object.keys(content)
    .map((field) => {
      const initialValue = initialState[field as keyof typeof content];

      const extendedField = getExtendedField(field);
      const lang = useMemberStore.getState().member.config.languageId;

      let value = formatValue(content[field as keyof typeof content] as unknown, field, lang);
      let label = getFieldText(field)?.label?.replace('*', '');

      if (initialValue === value) {
        return null;
      }

      if (extendedField) {
        value = formatValue(
          content[extendedField.name as keyof typeof content] as unknown,
          extendedField.name,
          lang,
        );
        label = extendedField.label;
      }
      if (value && label && !field.startsWith('use')) {
        return {
          value,
          label,
          field,
        };
      }

      return null;
    })
    .filter((field) => field !== null);
  return fields && fields.length > 0 ? (
    <Grid container columns={1} mb="md">
      <Heading type="h3" text={getParagraphText(areaName)} />
      <Grid container sm={2}>
        {fields
          .filter((field): field is NonNullable<typeof field> => field !== null)
          .map(({ value, label, field }) => (
            <Group>
              <Paragraph condensed text={`${label}:`} medium />
              <Paragraph condensed id={field} text={value} data-cy={field} />
            </Group>
          ))}
      </Grid>
    </Grid>
  ) : null;
};

export default ReviewContent;
