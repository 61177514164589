import { List } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import { getCategorizedErrorMessages } from '../../data/helpers';

type TProps = {
  errors: { [field: string]: string };
};

const ErrorMessages: React.FC<TProps> = ({ errors }) => {
  const getErrors = () => {
    const err = Object.entries(errors).filter((e) => e);
    return err;
  };

  const categorizedErrors = React.useMemo(() => getCategorizedErrorMessages(getErrors()), [errors]);

  return (
    <List
      type="unordered"
      items={categorizedErrors.map((category) => ({
        text: category.label,
        nested: category.errors?.map((error) => ({
          text: error,
        })),
      }))}
    />
  );
};

export default ErrorMessages;
