import { createStyles } from '@ingeniorforeningen/figurine-core';

export const useStyles = createStyles((theme) => ({
  desktopOnlyPaper: {
    [theme.fn.smallerThan('sm')]: {
        padding: 0,
        background: 'transparent',
        boxShadow: 'none',
    },
  },
  paperFriendlyInput: {
    [theme.fn.largerThan('sm')]: {
        input: {
            outline: '1px solid #0f45cc',
            borderRadius: '0.625rem',
        },
    }
  },
}));
