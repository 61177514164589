import { Grid, Heading, SegmentedControl } from '@ingeniorforeningen/figurine-core';
import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';
import DOMPurify from 'dompurify';
import { useConsent } from 'src/data/consent';
import useMemberStore from '../../store/member/memberStore';
import { TComponentProps } from '../../types';
import { createModalTriggerFromRichtext, formatCpr } from '../../utilities';
import { TextField } from '../inputs';
import { getFieldText, getParagraphText } from '../../data/helpers';

const CprInfo = ({ area }: TComponentProps): JSX.Element => {
  const { data: cprConsentInfo } = useConsent('cpr');
  const { useCprNo, updateMember } = useMemberStore(
    (state) => ({ useCprNo: state.member.personal.useCprNo, updateMember: state.updateMember }),
    shallow,
  );

  const helptext = `<b>${cprConsentInfo?.title}</b><br />${cprConsentInfo?.text} ${createModalTriggerFromRichtext(
    cprConsentInfo?.additionalText as string,
    getParagraphText('readMore'),
    cprConsentInfo?.title,
  )}`;

  useEffect(() => {
    updateMember([
      {
        property: 'cprConsent',
        value: { version: cprConsentInfo?.version?.full, allowed: useCprNo },
        areaName: 'config',
      },
    ]);
  }, [useCprNo]);

  return (
    <>
      <Grid item xs={12} sm={6} lg={4} spacing="xl">
        <Heading type="h4" text={getFieldText('useCprNo').label} />
        <SegmentedControl
          cypress="useCprNo"
          fullWidth
          data={[
            { label: getParagraphText('yesOptionLabel'), value: 'true' },
            { label: getParagraphText('noOptionLabel'), value: 'false' },
          ]}
          onChange={(value) => {
            updateMember([
              {
                property: 'useCprNo',
                value: value === 'true',
                areaName: 'personal',
              },
            ]);
          }}
        />
        {useCprNo && (
          <TextField
            field="cprNo"
            required
            area={area}
            formatValue={formatCpr}
            overwriteHelptext={DOMPurify.sanitize(helptext)}
            disabled={!useCprNo}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <div />
      </Grid>
    </>
  );
};

export default CprInfo;
