import useMemberStore from '../store/member/memberStore';

export const setLeadIdInState = (): void => {
  const uidFromUrl = new URLSearchParams(window.location.search).get('uid') as string;
  let leadIdFromUrl = new URLSearchParams(window.location.search).get('leadid') as string;
  if (leadIdFromUrl == null || leadIdFromUrl === undefined) {
    leadIdFromUrl = new URLSearchParams(window.location.search).get('leadId') as string;
  }

  // Sometimes both uid and leadId is provided in the url. We want to prioritize the uid,
  // but if thats not there and the leadId is, then we want the leadId
  if (uidFromUrl && uidFromUrl !== '') {
    useMemberStore
      .getState()
      .updateMember([{ property: 'leadId', value: uidFromUrl, areaName: 'config' }]);
  } else if (leadIdFromUrl && leadIdFromUrl !== '') {
    useMemberStore
      .getState()
      .updateMember([{ property: 'leadId', value: leadIdFromUrl, areaName: 'config' }]);
  }
};
