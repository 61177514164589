import {
  Paragraph,
  Heading,
  Breadcrumbs,
  Group,
  Container,
  Grid,
  Carousel,
} from '@ingeniorforeningen/figurine-core';
import { Situation } from '@ingeniorforeningen/member-signup-backend-client';
import {
  ContactInfo,
  SignupFlowCard,
  TSignupFlowCardProps,
} from '@ingeniorforeningen/figurine-cms';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import shallow from 'zustand/shallow';
import { useUmbraco, useUmbracoOld } from 'src/data/umbraco';
import { TLanguage } from '@ingeniorforeningen/umbraco-client';
import useMemberStore from '../../../store/member/memberStore';
import { TUmbracoNode } from '../../../store/umbraco/types';
import { formatUrl, translate } from '../../../utilities';
import { getParagraphText } from '../../../data/helpers';
import track from '../../../tracking';
import useUiStore from '../../../store/ui/uiStore';
import { readyUmbracoStepsForForm } from '../../../data/utilities';
import useUmbracoStore from '../../../store/umbraco/umbracoStore';
import { useStyles } from './styles';

type TProps = {
  content: TUmbracoNode;
};

const Frontpage: React.FC<TProps> = ({ content }) => {
  const { setSituation, leadId, lang } = useMemberStore(
    (state) => ({
      setSituation: state.setSituation,
      leadId: state.member.config.leadId,
      lang: state.member.config.languageId,
    }),
    shallow,
  );

  const { isInitialized, updateUi } = useUiStore(
    (state) => ({
      updateUi: state.updateUi,
      isInitialized: state.isInitialized,
    }),
    shallow,
  );

  const setStepNodes = useUmbracoStore((store) => store.setStepNodes);

  const navigate = useNavigate();
  const { data } = useUmbracoOld();
  const { data: miniCmsData } = useUmbraco(
    runtimeConfig.frontpageNodeGuid,
    lang.toLowerCase() as TLanguage,
  );

  const { classes } = useStyles();

  const cards = translate(content, 'cards') as Record<string, string>[];
  const formatCards = (items: Record<string, string>[]): Record<string, string | string[]>[] =>
    items.map((item) => ({
      ...item,
      list: item.list.split('\r\n'),
    }));

  if (!isInitialized) {
    track.signup({
      language: lang.toLowerCase(),
      leadId,
    });
    updateUi('isInitialized', true);
  }

  const handleClick = (situation: Situation): void => {
    const formSteps = readyUmbracoStepsForForm(situation, data);
    setSituation(situation);
    setStepNodes(formSteps);

    track.memberType({
      situation,
      language: lang,
      leadId,
    });

    if (formSteps.length > 0) {
      track.goForward({
        stepName: formSteps[0].name as string,
        situation,
        language: lang.toLowerCase(),
        leadId,
      });

      navigate(formatUrl(formSteps[0].slug as string), { replace: true });
    } else {
      track.exception({
        errorcode: 500,
        id: 'Umbraco not found',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{getParagraphText('subHeader')}</title>
      </Helmet>

      <Group className={classes.background} direction="column">
        <Breadcrumbs
          variant="light"
          homeHref={runtimeConfig.idadkDomain as string}
          items={[
            {
              label: 'Bliv Medlem',
              link: `${runtimeConfig.idadkDomain}/bliv-medlem`,
            },
          ]}
        />
        <Container>
          <Grid container className={classes.textWrapper}>
            <Grid item>
              <Heading
                type="display"
                text={miniCmsData?.Frontpage?.Title?.value as string}
                variant="light"
                align="center"
              />
            </Grid>
            <Grid item className={classes.teaser} justify="center" mb="xl">
              <Paragraph align="center" text={translate(content, 'teaser')} variant="light" />
            </Grid>
          </Grid>

          <Group className={classes.cardsWrapper}>
            {cards && (
              <Carousel breakPoints={[{ minWidth: 'xs', slideSize: '21.5em' }]} draggable>
                {formatCards(cards).map((card) => (
                  <SignupFlowCard
                    id={card.memberType as string}
                    figure={
                      (card.image as string).toLocaleLowerCase() as TSignupFlowCardProps['figure']
                    }
                    header={card.title as string}
                    price={card.teaserDetails as string}
                    benefits={card.list as string[]}
                    cta={card.buttonText as string}
                    freePeriod={parseInt(
                      (card.teaser as string).replace('<p>', '').replace('</p>', ''),
                      10,
                    )}
                    onClick={() => {
                      handleClick(Situation[card.memberType as keyof typeof Situation]);
                    }}
                  />
                ))}
              </Carousel>
            )}
          </Group>
        </Container>
      </Group>

      <ContactInfo
        mt="xl"
        title={translate(content, 'contactTitle')}
        text={translate(content, 'contactDescription')}
        link={{
          href: content.contactLinkUrl as string,
          text: translate(content, 'contactLinkText'),
        }}
      />
    </>
  );
};

export default Frontpage;
