import { Grid } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import { getFieldText, getMonths, getYears } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';
import { TComponentProps } from '../../types';
import { Select } from '../inputs';

const EducationDates: React.FC<TComponentProps> = ({ area }) => {
  const { isStudent } = useMemberStore((state) => ({
    isStudent: state.member.education.isStudent,
  }));

  const currentYear = new Date().getFullYear();
  const minYear = isStudent ? currentYear : undefined;
  const maxYear = isStudent ? undefined : currentYear;

  return (
    <>
      {isStudent && (
        <Grid container columns={9} spacing="xl">
          <Grid item xs={9} sm={3}>
            <Select
              field="startYear"
              options={getYears(undefined, currentYear + 1)}
              area={area}
              overwriteHelpText={getFieldText('startYear').helpText}
              searchable
              required
            />
          </Grid>

          <Grid item xs={9} sm={3}>
            <Select
              field="startMonth"
              options={getMonths()}
              area={area}
              overwriteHelpText={getFieldText('startMonth').helpText}
              searchable
              required
            />
          </Grid>
        </Grid>
      )}
      <Grid container columns={9} spacing="xl">
        <Grid item xs={9} sm={3}>
          <Select
            field="endYear"
            options={getYears(minYear, maxYear)}
            area={area}
            overwriteHelpText={getFieldText(isStudent ? 'endYearStudent' : 'endYear').helpText}
            overwriteLabel={getFieldText(isStudent ? 'endYearStudent' : 'endYear').label}
            searchable
            required
          />
        </Grid>

        <Grid item xs={9} sm={3}>
          <Select
            field="endMonth"
            options={getMonths()}
            area={area}
            overwriteHelpText={getFieldText(isStudent ? 'endMonthStudent' : 'endMonth').helpText}
            overwriteLabel={getFieldText(isStudent ? 'endMonthStudent' : 'endMonth').label}
            searchable
            required
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EducationDates;
