/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import App from './App';
import { reactPlugin, appInsights } from './tracking/developer/appInsights';

appInsights.loadAppInsights();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <App />
    </AppInsightsContext.Provider>
  </React.StrictMode>,
);
