import { Stepper } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import track from 'src/tracking';
import useUiStore from '../../store/ui/uiStore';
import { TUmbracoNode } from '../../store/umbraco/types';
import useUmbracoStore from '../../store/umbraco/umbracoStore';
import { useStyles } from './styles';

const ProgressIndicator = () => {
  const currentStep = useUiStore((state) => state.currentStep);
  const steps = useUmbracoStore((state) => state.stepNodes);
  const { classes } = useStyles();

  const formatSteps = () =>
    steps.map((step: TUmbracoNode) => ({
      label: `${step.header}`,
    }));

  const handleClick = () => {
    track.event({ name: 'Pressed progress indicator', properties: { currentStep } });
  };

  return (
    <Stepper
      className={classes.wrapper}
      items={formatSteps()}
      active={currentStep - 1}
      onClick={handleClick}
    />
  );
};

export default ProgressIndicator;
