import { Signup } from '@ingeniorforeningen/member-signup-backend-client';
import { IJobExtended, JobExtended } from './JobExtended';
import { EducationExtended, IEducationExtended } from './EducationExtended';
import { IPersonalExtended, PersonalExtended } from './PersonalExtended';
import { ConfigExtended, IConfigExtended } from './ConfigExtended';
import { GiftExtended, IGiftExtended } from './GiftExtended';

export interface ISignupExtended {
  config: IConfigExtended;
  education: IEducationExtended;
  personal: IPersonalExtended;
  job: IJobExtended;
  gift: IGiftExtended;
}

export class SignupExtended extends Signup {
  personal: PersonalExtended;

  education: EducationExtended;

  job: JobExtended;

  config: ConfigExtended;

  gift: GiftExtended;

  constructor(data: ISignupExtended) {
    super();
    this.personal = new PersonalExtended(data.personal);
    this.education = new EducationExtended(data.education);
    this.job = new JobExtended(data.job);
    this.config = new ConfigExtended(data.config);
    this.gift = new GiftExtended(data.gift);
  }
}
