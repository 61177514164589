import { Grid } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import { onlyAllowNumbers } from '../../utilities';
import { TComponentProps } from '../../types';
import { TextField } from '../inputs';
import Address from './dynamic/Address';

const OtherWorkplace: React.FC<TComponentProps> = ({ area }): JSX.Element => (
  <Grid container columns={12} spacing="xl">
    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="otherWorkplace" area={area} required/>
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="cvr" area={area} formatValue={onlyAllowNumbers} />
    </Grid>
    <Address area={area} />
  </Grid>
);

export default OtherWorkplace;
