import React from 'react';
import { Grid, Paper, Icon } from '@ingeniorforeningen/figurine-core';

type AccessDeniedProps = {
  roles?: string[];
};

const AccessDenied = ({ roles }: AccessDeniedProps) => (
    <Grid container columns={12}>
        <Grid item xs={6}>
          <Paper>
            <Grid container columns={12}>
              <Grid item xs={2}>
                <Icon name="AlertCircle" />
              </Grid>
              <Grid item xs={10}>
                Din IDA bruger har ikke adgang til denne del af systemet.
                {roles?.length && (
                  <span>
                    For at få adgang skal din bruger have tilknyttet en af følgende roller:
                    <cite>{roles.join(', ')}</cite>
                  </span>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
  );

export default AccessDenied;
