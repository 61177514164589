import React from 'react';
import { Grid } from '@ingeniorforeningen/figurine-core';
import shallow from 'zustand/shallow';
import { useInstitutions, useSpecializations } from 'src/data/educations';
import { Select, Checkbox } from '../inputs';
import { TComponentProps } from '../../types';
import { getFieldText } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';

const Institution: React.FC<TComponentProps> = ({ area }) => {
  const { isStudent, useAbroadEducation, useOtherSpecialization, institutionId, isPhd } =
    useMemberStore(
      (state) => ({
        specializationId: state.member.education.specializationId,
        isPhd: state.member.education.isPhd,
        useAbroadEducation: state.member.education.useAbroadEducation,
        useOtherSpecialization: state.member.education.useOtherSpecialization,
        institutionId: state.member.education.institutionId,
        isStudent: state.member.education.isStudent,
        updateMember: state.updateMember,
      }),
      shallow,
    );

  const { data: institutions } = useInstitutions();
  const { data: specializations } = useSpecializations(institutionId);

  return (
    <Grid container columns={3} spacing="xl">
      <Grid item xs={3} sm={1}>
        <Select
          field="institutionId"
          area={area}
          options={institutions || []}
          disabled={useAbroadEducation}
          showHelpText={isStudent}
          searchable
          required
        />
        {!isStudent && !isPhd && (
          <Checkbox
            field="useAbroadEducation"
            checked={useAbroadEducation}
            disableCheck="useOtherSpecialization"
            area={area}
          />
        )}
      </Grid>

      {institutionId && (
        <Grid item xs={3} sm={1}>
          <Select
            field="specializationId"
            area={area}
            overwriteLabel={isStudent ? getFieldText('specializationIdStudent').label : undefined}
            overwritePlaceholder={
              isStudent ? getFieldText('specializationIdStudent').placeholder : undefined
            }
            overwriteHelpText={
              isStudent ? getFieldText('specializationIdStudent').helpText : undefined
            }
            showHelpText={isStudent}
            options={specializations || []}
            disabled={!institutionId || useOtherSpecialization || useAbroadEducation}
            searchable
            required
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Institution;
