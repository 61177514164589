/* eslint-disable no-param-reassign */
import create, { GetState } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';
import { Situation, PreferredLanguage } from '@ingeniorforeningen/member-signup-backend-client';
import { EducationInstitution, EducationSpecialization } from '@ingeniorforeningen/uidata-client';
import shallow from 'zustand/shallow';
import { produce } from 'immer';
import { AxiosResponse } from 'axios';
import { queryClient } from 'src/data/clients';
import { getPostalDistricts } from 'src/data/personal';
import { memberInitialState } from './initialState';
import { handleSetSituation, handleUnusedProperties, handleUpdatingMember } from './helpers';
import { TMemberStore, TUpdateMember } from './types';
import useUmbracoStore from '../umbraco/umbracoStore';
import { TUmbracoNode, TUmbracoTemplates } from '../umbraco/types';

const store = (set: NamedSet<TMemberStore>, get: GetState<TMemberStore>) => ({
  member: memberInitialState,
  resetMember: (situation: Situation = Situation.Unknown) => {
    set(
      produce((state) => {
        state.member = memberInitialState;
        state.member.job.situationId = situation;
      }),
    );
  },
  refreshMember: () => {
    handleUnusedProperties(set, get);
  },
  updateMember: (properties: TUpdateMember[]) => {
    handleUpdatingMember(set, get().member, properties);
  },
  setSituation: (situation: Situation) => {
    handleSetSituation(set, situation);
  },
  resetEducation: () => {
    set(
      produce((state) => {
        state.member.education.levelId = '';
        state.member.education.levelName = '';
        state.member.education.specializationId = '';
        state.member.education.specializationName = '';
      }),
    );
  },
  resetInstitution: () => {
    set(
      produce((state) => {
        state.member.education.institutionId = '';
        state.member.education.institutionName = '';
        state.member.education.levelId = '';
        state.member.education.levelName = '';
        state.member.education.specializationId = '';
        state.member.education.specializationName = '';
        state.member.education.useOtherSpecialization = false;
      }),
    );
  },
});

const useMemberStore = create<TMemberStore>(devtools(store, { name: 'memberStore' }));

useMemberStore.subscribe(
  async (newState: unknown[]) => {
    const endYear = +(newState[0] as string);
    const endMonth = +(newState[1] as string);
    const today = new Date();

    const diffYears = endYear - today.getFullYear();
    const diffMonths = diffYears * 12 + endMonth - (today.getMonth() + 1);
    const endsInTwoMonthsOrLess = diffMonths <= 2;

    useMemberStore
      .getState()
      .updateMember([
        { property: 'educationEndsSoon', value: endsInTwoMonthsOrLess, areaName: 'education' },
      ]);
  },
  (state) => [state.member.education.endYear, state.member.education.endMonth],
  shallow,
);

useMemberStore.subscribe(
  () => useMemberStore.getState().resetInstitution(),
  (state) => [state.member.education.useAbroadEducation],
  shallow,
);

useMemberStore.subscribe(
  () => useMemberStore.getState().resetEducation(),
  (state) => [state.member.education.institutionId],
  shallow,
);

useMemberStore.subscribe(
  () =>
    useMemberStore
      .getState()
      .updateMember([{ property: 'startMonth', value: '', areaName: 'education' }]),
  (state) => [state.member.education.startYear],
  shallow,
);

useMemberStore.subscribe(
  async (postal) => {
    const country = useMemberStore.getState().member.personal.countryId;
    const districts = await getPostalDistricts(country, postal as string);
    const city = districts && districts[0];

    if (city) {
      useMemberStore.getState().updateMember([
        { property: 'cityName', value: city?.name || '', areaName: 'personal' },
        { property: 'cityId', value: city?.cityId || '', areaName: 'personal' },
        {
          property: 'postalDistrictId',
          value: postal ? city?.id || 'invalid' : '',
          areaName: 'personal',
        },
        { property: 'kommuneId', value: city?.kommuneId || '', areaName: 'personal' },
        { property: 'kredsId', value: city?.kredsId || '', areaName: 'personal' },
      ]);
    }
  },
  (state) => state.member.personal.postalDistrictName,
  shallow,
);

// This is a copy-paste of above subscription. We keep them seperate because of areas
// if a more optimized solution is found please update
useMemberStore.subscribe(
  async (postal) => {
    const country = useMemberStore.getState().member.job.countryId;
    const districts = await getPostalDistricts(country, postal as string);
    const city = districts && districts[0];

    if (city) {
      useMemberStore.getState().updateMember([
        { property: 'cityName', value: city?.name || '', areaName: 'job' },
        { property: 'cityId', value: city?.cityId || '', areaName: 'job' },
        {
          property: 'postalDistrictId',
          value: postal ? city?.id || 'invalid' : '',
          areaName: 'job',
        },
        { property: 'kommuneId', value: city?.kommuneId || '', areaName: 'job' },
        { property: 'kredsId', value: city?.kredsId || '', areaName: 'job' },
      ]);
    }
  },
  (state) => state.member.job.postalDistrictName,
  shallow,
);

// The subscription below has reversed the naming of its states.
// (prev, newState) should be (newState, prev) or just (newState).
// The "shallow" eval function could probably be re-introduced after the fix.
useMemberStore.subscribe(
  async (prev, [isStudent, useAbroadEducation, languageId]: (string | boolean)[]) => {
    const umbraco: AxiosResponse | undefined = await queryClient.getQueryData(['umbraco']);
    const isEnglish = languageId === PreferredLanguage.En;
    if (umbraco?.data) {
      const receipts = umbraco.data.filter((node: TUmbracoNode) => {
        const slug = node.slug as string;
        return node.template === TUmbracoTemplates.membersignupRecieptPage && isEnglish
          ? slug.includes('/en/')
          : !slug.includes('/en');
      });

      useUmbracoStore
        .getState()
        .setReceiptNode(receipts, isStudent as boolean, useAbroadEducation as boolean);
    }
  },
  (state) => [
    state.member.education.isStudent,
    state.member.education.useAbroadEducation,
    state.member.config.languageId,
  ],
);

useMemberStore.subscribe(
  async ([institutionId, specializationId]: string[]) => {
    const specializations = (await queryClient.getQueryData([
      'specializations',
      institutionId,
    ])) as EducationSpecialization[];

    if (specializationId && specializationId !== '') {
      const specialization = specializations?.find((s) => s.id === specializationId);

      if (specialization) {
        const institutions = (await queryClient.getQueryData([
          'institutions',
        ])) as EducationInstitution[];

        const institution = institutions?.find((i) => i.id === institutionId);
        const level = institution?.educationLevels?.find((l) => l.id === specialization?.levelId);

        useMemberStore.getState().updateMember([
          { property: 'levelId', value: level?.id, areaName: 'education' },
          { property: 'levelName', value: level?.name, areaName: 'education' },
        ]);
      }
    }
  },
  (state) => [state.member.education.institutionId, state.member.education.specializationId],
  shallow,
);

export default useMemberStore;
