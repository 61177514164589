import React from 'react';
import { AuthConsumer } from './AuthProvider';

const SilentRenew: React.FC = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return <div />;
    }}
  </AuthConsumer>
);

export default SilentRenew;
