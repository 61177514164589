import { useQuery } from '@tanstack/react-query';
import {
  ConvertToDictionary,
  PagedIApiContentResponseModel,
  TLanguage,
} from '@ingeniorforeningen/umbraco-client';
import { TUmbracoNode } from 'src/store/umbraco/types';
import { umbracoClient, umbracoClientOld } from './clients';
import { extractReciepts } from './utilities';
import { TUmbracoDictionaryResult } from './types';

export const useUmbracoOld = (filter?: string) =>
  useQuery({
    queryKey: ['umbraco'],
    queryFn: async () => umbracoClientOld,
    select: (data): TUmbracoNode[] => {
      switch (filter) {
        case 'receipts':
          return extractReciepts(data.data);
        default:
          return data.data;
      }
    },
  });

export const useUmbraco = (guid: string, language: TLanguage) =>
  useQuery({
    queryKey: ['umbraco', guid],
    queryFn: async () =>
      umbracoClient.getContentById({
        id: guid,
        accept_Language: language,
        preview: false,
        start_Item: 'mini-cmser',
      }),
    select: (data): TUmbracoDictionaryResult =>
      ConvertToDictionary(
        { total: 1, items: [data] } as PagedIApiContentResponseModel,
        false,
        runtimeConfig.env,
        language,
      ),
  });

export const useUmbracoGifts = (guid: string, language: TLanguage) =>
  useQuery({
    queryKey: ['umbracoGifts', guid],
    queryFn: async () =>
      umbracoClient.getContentById({
        id: guid,
        accept_Language: language,
        preview: false,
        start_Item: 'mini-cmser',
      }),
    select: (data): TUmbracoDictionaryResult => {
      const dictionaryData = ConvertToDictionary(
        { total: 1, items: [data] } as PagedIApiContentResponseModel,
        false,
        runtimeConfig.env,
        language,
      ) as TUmbracoDictionaryResult;

      const result = Object.values(dictionaryData.gifts).filter(
        (gift) => (gift as TUmbracoDictionaryResult).isActive.value,
      );

      return result;
    },
  });
