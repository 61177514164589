import { Situation } from '@ingeniorforeningen/member-signup-backend-client';
import { SignupExtended } from './areas/SignupExtended';
import { TMemberProperties } from './types';

export type TFilter = {
  reference: TMemberProperties;
  area: keyof SignupExtended;
  isEqual: (val: never) => boolean;
  include: TMemberProperties[];
  exclude: TMemberProperties[];
};

export const Filters: TFilter[] = [
  {
    reference: 'useCprNo',
    area: 'personal',
    isEqual: (val: boolean): boolean => val === true,
    include: ['street', 'street2', 'cityId', 'postalDistrictId', 'kommuneId', 'kredsId'],
    exclude: ['cprNo'],
  },
  {
    reference: 'situationId',
    area: 'job',
    isEqual: (val: string): boolean =>
      val === Situation.Unemployed ||
      val === Situation.Voluntary_early_retired ||
      val === Situation.Retired ||
      val === Situation.Student,
    include: [
      'postalDistrictId',
      'street',
      'street2',
      'cityId',
      'cvr',
      'title',
      'positionName',
      'workplaceName',
      'countryName',
    ],
    exclude: [],
  },
  {
    reference: 'countryName',
    area: 'job',
    isEqual: (val: string): boolean => val === 'Danmark',
    include: ['street2', 'street3'],
    exclude: ['postalDistrictId'],
  },
  {
    reference: 'countryName',
    area: 'personal',
    isEqual: (val: string): boolean => val === 'Danmark',
    include: ['street2', 'street3'],
    exclude: ['postalDistrictId', 'cityName', 'kommuneId', 'kredsId'],
  },
  {
    reference: 'useAbroadEducation',
    area: 'education',
    isEqual: (val: boolean): boolean => val === true,
    include: ['institutionId', 'levelId', 'specializationId'],
    exclude: [],
  },
  {
    reference: 'useOtherSpecialization',
    area: 'education',
    isEqual: (val: boolean): boolean => val === true,
    include: ['specializationId'],
    exclude: [],
  },
  {
    reference: 'useOtherWorkplace',
    area: 'job',
    isEqual: (val: boolean): boolean => val === true,
    include: ['workplaceId'],
    exclude: ['postalDistrictId', 'cityId', 'street', 'street2', 'street3', 'cvr'],
  },
];
