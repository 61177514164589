import { PreferredLanguage } from '@ingeniorforeningen/member-signup-backend-client';
import situations from '../data/local/situations.json';
import months from '../data/local/months.json';
import useMemberStore from '../store/member/memberStore';
import { TSelectOption } from '../types';

export const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

export const sortAlphabetically = (data: TSelectOption[]): TSelectOption[] => {
  data.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return data;
};

export const isTrue = (value: string | null): boolean => value != null;

export const longerThan = (min: number, value: string): boolean => value.toString().length >= min;

export const shorterThan = (max: number, value: string): boolean => value.toString().length <= max;

export const minMax = (min: number, max: number, value: string): boolean =>
  value.length <= max && value.length >= min;

export const isNumber = (value: string): boolean => /^\d+$/.test(value);

export const isCpr = (cpr: string): boolean => {
  const cprSplit = cpr.split('-');

  return (
    isNumber(cprSplit[0]) &&
    minMax(6, 6, cprSplit[0]) &&
    isNumber(cprSplit[1]) &&
    minMax(4, 4, cprSplit[1])
  );
};

export const isDate = (date: string): boolean => {
  const dateFormatted = Date.parse(date);
  if (!Number.isNaN(dateFormatted)) {
    return true;
  }

  return false;
};

export const isCvr = (value: string): boolean => {
  const cvrNumber = parseInt(value, 10);
  let isValid = `${cvrNumber}` !== 'NaN' && value.length === 8;

  if (isValid) {
    isValid = true;
    let cvr = 0;
    const sMul = '27654321';

    for (let i = 0; i < value.length; i += 1) {
      cvr += parseInt(value.substr(i, 1), 10) * parseInt(sMul.substr(i, 1), 10);
    }
    isValid = cvr % 11 === 0;
  }
  return isValid;
};

export const hasNumbers = (value: string): boolean => /\d/.test(value);

export const onlyAllowNumbers = (value: string): string => {
  if (value === undefined || isNumber(value)) {
    return value;
  }

  const formattedString = value.split('');
  const lastCharacter = formattedString[formattedString.length - 1];

  if (!isNumber(lastCharacter)) {
    formattedString.splice(formattedString.length - 1, 1);
    return formattedString.join('');
  }

  return value;
};

export const formatCpr = (cpr: string): string => {
  if (!cpr) {
    return '';
  }

  if (cpr.length > 7) {
    const formattedCpr = cpr.replace('-', '');
    return `${formattedCpr.substring(0, 6)}-${formattedCpr.substring(6)}`;
  }
  return cpr;
};

export const formatSituation = (situation: string, lang: string): string => {
  const selectedSituation: { da: string; en: string; dateType: string | null; hasJob: boolean } =
    situations[situation as keyof typeof situations];
  if (selectedSituation) {
    return lang === PreferredLanguage.Da ? selectedSituation.da : selectedSituation.en;
  }
  return situation;
};

export const groupByProp = (array: Record<string, unknown>[], propName: string): [] =>
  // TODO: Fix any type.
  // Can't seem to find the correct type for rv or x. We have to do with any for now.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  array.reduce((accumulated: any, node: any) => {
    // eslint-disable-next-line no-param-reassign
    accumulated[node[propName]] = node;
    return accumulated;
  }, {});

export const formatUrl = (path: string): string => {
  const { search } = window.location;
  return `${path}${search}`;
};

export const underscoreToCamelCase = (string: string): string => {
  let result = '';
  const splitted = string.split('_');
  splitted.forEach((part, index) => {
    if (index > 0) {
      result += capitalize(part);
    } else {
      result = part;
    }
  });

  return result;
};

export const addToUrlSearch = (params: { key: string; value: string }[]): void => {
  const searchParams = new URLSearchParams(window.location.search);

  params.forEach((param) => {
    searchParams.set(param.key, param.value);
  });

  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: newurl }, '', newurl);
};

export const getMonthName = (monthIndex: string): string => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const monthNames: string[] = months[language as keyof typeof months];
  let formattedIndex = monthIndex;
  if (monthIndex.startsWith('0')) {
    formattedIndex = monthIndex.replace('0', '');
  }

  return monthNames[formattedIndex as keyof typeof monthNames] as string;
};
export const formatMonthIndex = (monthIndex: string): string => {
  let result = monthIndex;
  if (result.startsWith('0')) {
    result = result.replace('0', '');
  }

  return result;
};

export const createModalTriggerFromRichtext = (
  content: string,
  label?: string,
  title?: string,
): string => {
  const span = document.createElement('span');
  span.setAttribute('data-type', 'modal');
  span.setAttribute('data-content', content);
  span.setAttribute('data-title', title || '');
  span.textContent = label || '';

  return span.outerHTML;
};

// We disable this rule here because translate can return multiple types and we don't want
// to cast to explicit type everytime we use this helper
// (which could break as easily as using any, so it doesn't make much of a difference anyway)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const translate = (page: unknown, propName: string): any => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();

  if (page) {
    return (page as Record<string, unknown>)[
      `${language}${capitalize(propName)}` as keyof typeof page
    ];
  }
  return '';
};
