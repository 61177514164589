import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import shallow from 'zustand/shallow';
import { Button, Container, Grid, Group, Html, Modal } from '@ingeniorforeningen/figurine-core';
import { Situation } from '@ingeniorforeningen/member-signup-backend-client';
import { useUmbracoOld } from 'src/data/umbraco';
import EducationDates from '../inputGroups/EducationDates';
import { TStepProps } from '../../types';
import { getFieldText, getParagraphText } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';
import useUiStore from '../../store/ui/uiStore';
import Institution from '../inputGroups/Institution';
import AbroadEducation from '../inputGroups/AbroadEducation';
import { readyUmbracoStepsForForm } from '../../data/utilities';
import useUmbracoStore from '../../store/umbraco/umbracoStore';

const Education: React.FC<TStepProps> = ({ area }) => {
  const { data } = useUmbracoOld();
  const setStepNodes = useUmbracoStore((store) => store.setStepNodes);
  const updateUi = useUiStore((state) => state.updateUi);
  const [isPhD, setIsPhD] = useState(false);
  const { situation, levelName, resetEducation, resetMember, useAbroadEducation } = useMemberStore(
    (state) => ({
      situation: state.member.job.situationId,
      levelName: state.member.education.levelName,
      updateMember: state.updateMember,
      resetEducation: state.resetEducation,
      setSituation: state.setSituation,
      resetMember: state.resetMember,
      useAbroadEducation: state.member.education.useAbroadEducation,
    }),
    shallow,
  );

  const checkPhDStatus = (level: string) => {
    const checks = ['phd', 'phd.', 'ph.d.', 'ph.d', 'p.h.d', 'p.h.d.'];
    for (let i = 0; i < checks.length; i += 1) {
      if (level.toLowerCase().includes(checks[i])) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    setIsPhD(checkPhDStatus(levelName));
  }, [levelName]);

  useEffect(() => {
    updateUi('area', area);
  }, []);

  const handleClose = () => {
    resetEducation();
    setIsPhD(false);
  };

  const handleAccept = () => {
    const newSituation = Situation.Employed;
    resetMember(newSituation);
    setIsPhD(false);

    const formSteps = readyUmbracoStepsForForm(newSituation, data);
    setStepNodes(formSteps);
    updateUi('currentStep', 1);

    window.scrollTo(0, 0);
  };

  return (
    <Container>
      <Helmet>
        <title>{getParagraphText('education')}</title>
      </Helmet>
      <Grid container>
        <Grid item spacing="xl">
          {situation === Situation.Student ? (
            <>
              <Institution area={area} />
              <EducationDates area={area} />
              <Modal
                title={getParagraphText('disclaimerPhDTitle')}
                opened={isPhD}
                onClose={handleClose}
              >
                <Html html={getParagraphText('disclaimerPhD')} mt="xl" />
                <Group justify="center" mt="xl">
                  <Button text={getFieldText('phdButton').label} onClick={handleAccept} />
                </Group>
              </Modal>
            </>
          ) : (
            <>
              <EducationDates area={area} />
              <Institution area={area} />
              {useAbroadEducation && <AbroadEducation area={area} />}
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Education;
