import React, { useEffect, useState } from 'react';
import { Grid, TSelectFieldItem, useDebouncedValue } from '@ingeniorforeningen/figurine-core';
import shallow from 'zustand/shallow';
import { useCompanies } from 'src/data/employment';
import { TextField, Select, Checkbox } from '../inputs';
import { TComponentProps } from '../../types';
import OtherWorkplace from './OtherWorkplace';
import { formatLocalToSelect } from '../../data/utilities';
import positionsData from '../../data/local/positions.json';
import useMemberStore from '../../store/member/memberStore';

const Job = ({ area }: TComponentProps): JSX.Element => {
  const { workplaceId, workplaceName, useOtherWorkplace } = useMemberStore(
    (state) => ({
      workplaceId: state.member.job.workplaceId,
      workplaceName: state.member.job.workplaceName,
      useOtherWorkplace: state.member.job.useOtherWorkplace,
    }),
    shallow,
  );
  const [fieldSearch, setFieldSearch] = useState('');
  const [searchQuery] = useDebouncedValue(fieldSearch, 200);
  const positions = formatLocalToSelect(positionsData);
  const { data: companiesList, isLoading } = useCompanies(searchQuery);
  const [companies, setCompanies] = useState<TSelectFieldItem[]>([]);

  useEffect(() => {
    if (workplaceId) {
      setCompanies([{ label: workplaceName, value: workplaceId }]);
    }
  }, []);

  useEffect(() => {
    if (companiesList && companiesList.length > 0) {
      setCompanies(companiesList);
    }
  }, [companiesList]);

  return (
    <Grid container columns={12} spacing="xl">
      <Grid item xs={12} sm={6} lg={4}>
        <TextField field="title" area={area} required />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Select field="positionId" options={positions} area={area} required />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Select
          field="workplaceId"
          options={companies}
          area={area}
          api={(value) => setFieldSearch(value)}
          disabled={useOtherWorkplace}
          searchable
          required
          loading={isLoading || !companies}
        />
        <Checkbox field="useOtherWorkplace" checked={useOtherWorkplace} area={area} />
      </Grid>
      <Grid item xs={12}>
        {useOtherWorkplace && <OtherWorkplace area={area} />}
      </Grid>
    </Grid>
  );
};

export default Job;
