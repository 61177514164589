/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { UserManager, WebStorageStateStore, Log, SigninRequest } from 'oidc-client';
import { formatUrl } from '../utilities';
import { TLogin, TAuthResult, IAuthentication } from './types';

// eslint-disable-next-line max-len
// This implementation draws it's inspiration from a medium post: https://medium.com/@franciscopa91/how-to-implement-oidc-authentication-with-react-context-api-and-react-router-205e13f2d49
export default class Authentication implements IAuthentication {
  public static UserManager: UserManager;

  userManager: UserManager;

  accessToken = '';

  constructor(login: TLogin) {
    const IDENTITY_CONFIG = {
      authority: login.authority,
      client_id: login.client_id,
      redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/signin-oidc`,
      silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/silent-renew-oidc`,
      post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/signout-callback-oidc`,
      automaticSilentRenew: true,
      loadUserInfo: true,
      response_type: 'code',
      scope: 'openid profile roles member-signup.user',
      filterProtocolClaims: true,
    };

    Authentication.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    this.userManager = Authentication.UserManager;

    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;

    this.userManager.events.addUserLoaded((user) => {
      console.log(user);
      this.accessToken = user.access_token;
      localStorage.setItem('access_token', user.access_token);
      localStorage.setItem('id_token', user.id_token);
      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token,
      });
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });

    this.userManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpired((e) => {
      console.log('token expired');
      console.log(e);
      this.signinSilent();
    });
  }

  signinRedirectCallback = (): void => {
    this.userManager.signinRedirectCallback().then(() => {
      ('');
    });
  };

  getName = async (): Promise<string> => {
    const user = await this.userManager.getUser();
    if (user === undefined || user === null) {
      return '';
    }
    return user.profile.name || '';
  };

  parseJwt = (token: string): any => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  setUserInfo = (authResult: TAuthResult): void => {
    const data = this.parseJwt(this.accessToken);
    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = (): void => {
    localStorage.setItem('redirectUri', formatUrl(window.location.pathname));
    this.userManager.signinRedirect({});
  };

  setUser = (data: any): void => {
    localStorage.setItem('userId', data.sub);
    localStorage.setItem('roles', JSON.stringify(data.roles));
  };

  navigateToScreen = (): void => {
    const uri = localStorage.getItem('redirectUri');
    const redirectUri = uri || formatUrl('/');
    window.location.replace(redirectUri);
  };

  setSessionInfo = (authResult: TAuthResult) => {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
  };

  isAuthenticated = (): boolean => {
    const accessToken = localStorage.getItem('access_token');
    return Boolean(accessToken && this.parseJwt(accessToken).exp > Date.now() / 1000);
  };

  hasRole = (roleName: string) => {
    const roles = JSON.parse(localStorage.getItem('roles') || '[]');
    return roles && roles.some((x: string) => x === roleName);
  };

  signinSilent = async () => {
    try {
      const user = await this.userManager.signinSilent();
      console.log('signed in', user);
    } catch (err: any) {
      // A new access token could not be issued from Koncernlogin
      if (err.error === 'login_required') {
        // If a valid access token is required, force the user to login
        // this.signinRedirect();

        // If the access token is not required, then just clear the token
        localStorage.clear();
        this.userManager.clearStaleState();
      } else {
        console.log(err);
      }
    }
  };

  signinSilentCallback = (): void => {
    this.userManager.signinSilentCallback();
  };

  createSigninRequest = (): Promise<SigninRequest> => this.userManager.createSigninRequest();

  logout = () => {
    this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.userManager.clearStaleState();
  };

  signoutRedirectCallback = async () => {
    await this.userManager.signoutRedirectCallback();
    localStorage.clear();
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`,
    );
    this.userManager.clearStaleState();
  };
}
