import React, { useRef, useEffect, useState, ReactNode } from 'react';
import Authentication from '../../authentication/authentication';
import { IAuthentication, TLogin } from '../../authentication/types';

const AuthContext = React.createContext<IAuthentication>({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => false,
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
  hasRole: () => true,
  getName: async () => '',
});

export const AuthConsumer = AuthContext.Consumer;

type AuthProviderProps = {
  login: TLogin;
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ login, children }) => {
  const authentication = useRef<IAuthentication>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    authentication.current = new Authentication(login);
    setInitialized(true);
  }, [login]);

  return (
    initialized &&
    authentication.current && (
      <AuthContext.Provider value={authentication.current}>{children}</AuthContext.Provider>
    )
  );
};
