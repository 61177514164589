import {
  ManagerialResponsibility,
  PreferredLanguage,
  Situation,
  Consent,
  SignupSource,
} from '@ingeniorforeningen/member-signup-backend-client';
import { v4 as uuid } from 'uuid';
import { SignupExtended } from './areas/SignupExtended';
import { GiftType } from './areas/GiftExtended';

export const memberInitialState = new SignupExtended({
  config: {
    leadId: uuid(),
    languageId: PreferredLanguage.Da,
    utmSource: '', // TODO: Deprecated as a tracking variable, remove from service
    utmMedium: '', // TODO: Deprecated as a tracking variable, remove from service
    utmCampaign: '', // TODO: Deprecated as a tracking variable, remove from service
    cprConsent: new Consent({ version: '', allowed: false }),
    marketingConsent: new Consent({ version: '', allowed: false }),
    signupSource: SignupSource.MemberSignupWeb,
  },
  personal: {
    firstName: '',
    lastName: '',
    cprNo: '',
    email: '',
    phone: '',
    street: '',
    street2: '',
    street3: '',
    postalDistrictName: '',
    postalDistrictId: '',
    cityName: '',
    cityId: '',
    countryName: 'Danmark',
    countryId: runtimeConfig.denmarkId || '',
    useCprNo: true,
    kommuneId: '',
    kredsId: '',
  },
  education: {
    endYear: '',
    endMonth: '',
    endMonthName: '',
    startYear: '',
    startMonth: '',
    institutionName: '',
    institutionId: '',
    levelName: '',
    levelId: '',
    specializationName: '',
    specializationId: '',
    useAbroadEducation: false,
    abroadInstitution: '',
    abroadLevel: '',
    abroadSpecialization: '',
    useOtherSpecialization: false,
    isStudent: false,
    isPhd: false,
    educationEndsSoon: false,
  },
  job: {
    situationName: '',
    situationId: Situation.Unknown,
    title: '',
    positionName: '',
    positionId: ManagerialResponsibility.Unknown,
    workplaceName: '',
    workplaceId: '',
    otherWorkplace: '',
    cvr: '',
    street: '',
    street2: '',
    street3: '',
    postalDistrictName: '',
    postalDistrictId: '',
    cityName: '',
    cityId: '',
    countryName: 'Danmark',
    countryId: runtimeConfig.denmarkId || '',
    useOtherWorkplace: false,
  },
  gift: {
    campaignCode: '',
    campaignId: '',
    selectedGift: '',
    type: GiftType.none,
  },
});
