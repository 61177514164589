import { IPersonal, Personal } from "@ingeniorforeningen/member-signup-backend-client";


export interface IPersonalExtended extends IPersonal{
  countryName: string;
  cityName: string;
  postalDistrictName: string;
}

export class PersonalExtended extends Personal {
  countryName: string;

  postalDistrictName: string;

  cityName: string;

  constructor(data: IPersonalExtended) {
    super(data);
    this.countryName = data.countryName;
    this.cityName = data.cityName;
    this.postalDistrictName = data.postalDistrictName;
  }
}