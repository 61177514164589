import React, { useEffect, useState } from 'react';
import { SelectField as FigSelect, TSelectFieldProps } from '@ingeniorforeningen/figurine-core';
import shallow from 'zustand/shallow';
import { getFieldText, getParagraphText } from '../../data/helpers';
import { useFieldValidation } from '../../validation/validator';
import useMemberStore from '../../store/member/memberStore';
import { TAreaName, TMemberAreas, TMemberProperties } from '../../store/member/types';

type TProps = {
  field: TMemberProperties;
  options: TSelectFieldProps['options'];
  area: TAreaName;
  disabled?: boolean;
  overwriteHelpText?: string;
  showHelpText?: boolean;
  api?: (val: string) => void;
  formatValue?: (value: string) => string;
  overwriteValue?: string;
  validFeedback?: (isValid: boolean) => void;
  searchable?: boolean;
  required?: boolean;
  overwriteLabel?: string;
  overwritePlaceholder?: string;
  loading?: boolean;
};

const Select: React.FC<TProps> = ({
  field,
  formatValue,
  overwriteValue,
  area,
  searchable,
  options,
  disabled = false,
  api,
  validFeedback,
  overwriteHelpText,
  showHelpText = true,
  required = false,
  overwriteLabel,
  overwritePlaceholder,
  loading,
}): JSX.Element => {
  const validation = useFieldValidation(field, area, disabled);
  const [isDirty, setIsDirty] = useState(false);
  const { updateMember, valueFromState } = useMemberStore(
    (state) => ({
      updateMember: state.updateMember,
      valueFromState: state.member[area][field as keyof TMemberAreas],
    }),
    shallow,
  );

  useEffect(() => {
    if (validFeedback) {
      validFeedback(validation.valid);
    }
  }, [validation.valid]);

  const onChange: TSelectFieldProps['onExtendedChange'] = ({ value, label }) => {
    setIsDirty(true);

    const stringifiedValue: string = value;
    if (formatValue) {
      updateMember([{ property: field, value: formatValue(stringifiedValue), areaName: area }]);
    } else {
      updateMember([{ property: field, value, areaName: area }]);
    }

    // The field is an id an we save the matching name in the extended name prop
    if (field.endsWith('Id') || field.endsWith('Month')) {
      let extendedNameProp = field;

      if (field.endsWith('Id')) {
        extendedNameProp = field.replace('Id', 'Name') as TMemberProperties;
      } else if (field.endsWith('Month')) {
        extendedNameProp = `${field}Name` as TMemberProperties;
      }

      updateMember([{ property: extendedNameProp, value: label, areaName: area }]);
    }
  };

  return (
    <FigSelect
      name={field}
      id={field}
      value={overwriteValue || valueFromState}
      options={options}
      disabled={disabled}
      label={overwriteLabel || getFieldText(field).label}
      placeholder={overwritePlaceholder || getFieldText(field).placeholder}
      description={showHelpText ? overwriteHelpText || getFieldText(field).helpText : ''}
      onExtendedChange={onChange}
      onSearchChange={api}
      error={validation.showError && isDirty ? validation.message : ''}
      nothingFound={api ? getParagraphText('search') : ''}
      searchable={searchable}
      required={required}
      loading={loading}
    />
  );
};

export default Select;
