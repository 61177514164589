import React from 'react';
import { AuthConsumer } from './AuthProvider';

const LoginCallback: React.FC = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return <div />;
    }}
  </AuthConsumer>
);

export default LoginCallback;
