import { NamedSet } from 'zustand/middleware';
import { UiStep } from '../ui/types';
import {
  TAkaInfo,
  TContactInfo,
  TFeatureFlags,
  TLocalization,
  TUmbracoNode,
  TUmbracoStore,
  TUmbracoTemplates,
  umbracoStepNumberToUiStepMap,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleFeatureFlagsInOtherStores = (featureFlags: TFeatureFlags) => {
  // There are no current feature flags we need to handle.
  // This function stays here for simpler implementation for next feature flag.
};

const mapToHandledLanguagesUrl = (sharedDate: TUmbracoNode, key: string): TLocalization => {
  const { url: enUrl } = sharedDate[`en${key}`] as { url: string };
  const { url: daUrl } = sharedDate[`da${key}`] as { url: string };
  return {
    en: enUrl as string,
    da: daUrl as string,
  };
};

const mapToHandledLanguages = (sharedDate: TUmbracoNode, key: string): TLocalization => ({
    en: sharedDate[`en${key}`] as string,
    da: sharedDate[`da${key}`] as string,
  });

const handleContactInfo = (node: TUmbracoNode) => ({
    contactInfoCallToActionLink: mapToHandledLanguagesUrl(
      node,
      'EducationContactInfoCallToActionLink',
    ),
    contactInfoText: mapToHandledLanguages(node, 'EducationContactInfoText'),
    contactInfoCallToActionText: mapToHandledLanguages(
      node,
      'EducationContactInfoCallToActionText',
    ),
  } as TContactInfo);

const handleAkaInfo = (node: TUmbracoNode) => ({
    signupHeadline: mapToHandledLanguages(node, 'AkaSignupHeadline'),
    signupDescription: mapToHandledLanguages(node, 'AkaSignupText'),
    currentUnemploymentInsuranceFund: mapToHandledLanguages(
      node,
      'AkaSignupCurrentUnemploymentInsuranceFund',
    ),
    ctaText: mapToHandledLanguages(node, 'AkaSignupButtonText'),
    terms: mapToHandledLanguages(node, 'AkaTerms'),
    termsLabelText: mapToHandledLanguages(node, 'AkaTerms'),
    termsHeadline: mapToHandledLanguages(node, 'AkaSignupTermsHeadline'),
    signupSuccessfulHeadline: mapToHandledLanguages(node, 'AkaSignupSuccessfulHeadline'),
    signupSuccessfulDescription: mapToHandledLanguages(node, 'AkaSignupSuccessfulDescription'),
  } as TAkaInfo);

export const handleSharedData = (nodes: TUmbracoNode[], set: NamedSet<TUmbracoStore>): void => {
  const filteredNodes = nodes.filter((node) => node.template === TUmbracoTemplates.globalTexts);
  const contactInfo = handleContactInfo(filteredNodes?.[0] as TUmbracoNode);
  const akaInfo = handleAkaInfo(filteredNodes?.[0] as TUmbracoNode);
  set({ contactInfo, akaInfo });
};

export const handleFeatureFlags = (nodes: TUmbracoNode[], set: NamedSet<TUmbracoStore>): void => {
  const filteredNodes = nodes.filter((node) => node.template === TUmbracoTemplates.featureFlag);
  const featureFlags: TFeatureFlags = {};

  filteredNodes.forEach((node) => {
    featureFlags[node.name as string] = node.enabled as boolean;
  });

  set({ featureFlags });
  handleFeatureFlagsInOtherStores(featureFlags);
};

export const extractPageFromPath = (nodes: TUmbracoNode[], path: string): TUmbracoNode => {
  const redirectToHome = ['/en', '/form'];

  const homePage = nodes.find(
    (node: TUmbracoNode) => node.template === TUmbracoTemplates.membersignupFrontpage,
  );

  let page = nodes.find((node: TUmbracoNode) => node.slug === path);
  redirectToHome.forEach((redirect) => {
    if (redirect === page?.slug) {
      page = homePage;
    }
  });

  if (page) {
    return page;
  }
  return { error: 'not found' };
};

// Converts the currentStep from the uiStore to a UiStep used for resolving which component to render.
// It does this by using the umbracoStepNumberToUiStepMap, which holds the mapping between the two.
export const convertCurrentStepToUiStep = (currentStep: number, nodes: TUmbracoNode[]): UiStep => {
  const umbracoNodeStepNumber = nodes[currentStep - 1]?.stepNumber;
  return (
    umbracoStepNumberToUiStepMap.find((mapEntry) => mapEntry.stepNumber === umbracoNodeStepNumber)
      ?.uiStep || UiStep.education
  );
};

// Converts a UiStep to the corresponding Umbraco stepNumber, defaults to 2 (currently, the employment node)
export const convertUiStepToUmbracoStepNumber = (uiStep: UiStep): number => (
    umbracoStepNumberToUiStepMap.find((mapEntry) => mapEntry.uiStep === uiStep)?.stepNumber || 2
  );

// Determines if a step in the nodes array matches the umbraco mapping for the gift step
export const isStepEqualToGiftStep = (step: number, nodes: TUmbracoNode[]): boolean => {
  const umbracoNode = nodes[step - 1]?.stepNumber;
  const nodeExists = umbracoStepNumberToUiStepMap.find(
    (mapEntry) => mapEntry.stepNumber === umbracoNode,
  );
  return nodeExists?.uiStep === UiStep.gift;
};
