import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const axiosBaseApiClient: AxiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosBaseApiClientWithoutTransform: AxiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  transformResponse: [], // https://github.com/RicoSuter/NSwag/issues/3294
});

const onRequest = (request: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const token = localStorage.getItem('access_token');
  if (token && request.headers) {
    // request.headers.Authorization = `Bearer ${token}`;
  }
  return Promise.resolve(request);
};
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // const errorStatus = error.response?.status ?? 0;
  if (error) {
    // trackError(error, errorStatus);
  }
  return Promise.reject(error);
};
const onResponse = (response: AxiosResponse): Promise<AxiosResponse> => Promise.resolve(response);
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const errorStatus = error.response?.status ?? 0;
  if (error && errorStatus !== 401) {
    // Don't want to track 401 errors as they are expected
    // trackError(error, errorStatus);
  }
  return Promise.reject(error);
};

axiosBaseApiClientWithoutTransform.interceptors.request.use(onRequest, onRequestError);
axiosBaseApiClientWithoutTransform.interceptors.response.use(onResponse, onResponseError);
axiosBaseApiClient.interceptors.request.use(onRequest, onRequestError);
axiosBaseApiClient.interceptors.response.use(onResponse, onResponseError);

export { axiosBaseApiClient, axiosBaseApiClientWithoutTransform };
