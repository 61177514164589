import React from 'react';
import { AuthConsumer } from './AuthProvider';

const LogoutCallback: React.FC = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback();
      return <div />;
    }}
  </AuthConsumer>
);

export default LogoutCallback;
