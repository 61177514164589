import React from 'react';
import { Checkbox as FigCheckbox } from '@ingeniorforeningen/figurine-core';
import shallow from 'zustand/shallow';
import DOMPurify from 'dompurify';
import { getFieldText } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';
import { TAreaName, TMemberAreas, TMemberProperties } from '../../store/member/types';

type TProps = {
  label?: string;
  field: TMemberProperties;
  checked: boolean;
  area: TAreaName;
  disableCheck?: TMemberProperties;
  onChange?: () => void;
  required?: boolean;
};

const Checkbox = ({
  field,
  checked,
  area,
  disableCheck,
  label,
  required = false,
  onChange,
}: TProps) => {
  const { valueFromState, updateMember } = useMemberStore(
    (state) => ({
      valueFromState: state.member[area][field as keyof TMemberAreas],
      updateMember: state.updateMember,
    }),
    shallow,
  );

  const onInputChange = () => {
    if (onChange) {
      onChange();
    } else {
      updateMember([{ property: field, value: !valueFromState, areaName: area }]);
    }

    if (disableCheck && area) {
      updateMember([{ property: disableCheck, value: false, areaName: area }]);
    }
  };

  return (
    <FigCheckbox
      id={field}
      name={field}
      text={DOMPurify.sanitize(label || getFieldText(field).label)}
      checked={checked}
      onChange={onInputChange}
      required={required}
    />
  );
};

export default Checkbox;
