import { Grid, Html } from '@ingeniorforeningen/figurine-core';
import React from 'react';
import shallow from 'zustand/shallow';
import { useConsent } from 'src/data/consent';
import { getParagraphText } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';
import { createModalTriggerFromRichtext } from '../../utilities';
import { Checkbox } from '../inputs';

type TProps = {
  lawsAndStatutes: string;
  privacyPolicy: string;
};

const Consent: React.FC<TProps> = ({ lawsAndStatutes, privacyPolicy }) => {
  const { data: marketingConsentInfo, isLoading } = useConsent('marketing');
  const { marketingConsent, updateMember } = useMemberStore(
    (state) => ({
      marketingConsent: state.member.config.marketingConsent?.allowed || false,
      updateMember: state.updateMember,
    }),
    shallow,
  );
  updateMember([
    {
      property: 'marketingConsent',
      value: { version: marketingConsentInfo?.version?.full, allowed: marketingConsent },
      areaName: 'config',
    },
  ]);

  return (
    <div>
      {isLoading || !marketingConsentInfo ? (
        <div />
      ) : (
        <Grid container columns={12}>
          <Grid item xs={12} sm={10}>
            <Html html={privacyPolicy} sx={{ '*': { display: 'inline' } }} />
            <Html html={lawsAndStatutes} sx={{ '*': { display: 'inline' } }} />
            <Checkbox
              label={`<p>${marketingConsentInfo.text} ${createModalTriggerFromRichtext(
                marketingConsentInfo.additionalText as string,
                getParagraphText('readMore'),
                marketingConsentInfo.title,
              )}</p>`}
              onChange={() => {
                updateMember([
                  {
                    property: 'marketingConsent',
                    value: {
                      version: marketingConsentInfo.version?.full,
                      allowed: !marketingConsent,
                    },
                    areaName: 'config',
                  },
                ]);
              }}
              field="marketingConsent"
              checked={marketingConsent}
              area="config"
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Consent;
