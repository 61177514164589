import create from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';
import { extractPageFromPath, handleFeatureFlags, handleSharedData } from './helpers';
import { TContactInfo, TAkaInfo, TUmbracoNode, TUmbracoStore } from './types';

const store = (set: NamedSet<TUmbracoStore>) => ({
  featureFlags: {},
  contactInfo: {} as TContactInfo,
  akaInfo: {} as TAkaInfo,
  stepNodes: [] as TUmbracoNode[],
  currentNode: {},
  receiptNode: {},
  setCurrentNode: (nodes: TUmbracoNode[], path: string) => {
    handleSharedData(nodes, set);
    handleFeatureFlags(nodes, set);
    if (nodes.length > 0) {
      const page = extractPageFromPath(nodes, path);
      set({ currentNode: page });
    }
  },
  setReceiptNode: (receipts: TUmbracoNode[], isStudent: boolean, abroadEducation: boolean) => {
    let node: TUmbracoNode = {};

    // We don't take SignupFailed or ExistingMember into account, because we can't
    // know these scenarios without contacting CRM
    if (abroadEducation) {
      node = receipts.find((receipt) => receipt.memberType === 'ManualProcess') as TUmbracoNode;
    } else if (isStudent) {
      node = receipts.find((receipt) => receipt.memberType === 'Student') as TUmbracoNode;
    } else {
      node = receipts.find((receipt) => receipt.memberType === 'MemberCreated') as TUmbracoNode;
    }

    set({ receiptNode: node });
  },
  setStepNodes: (nodes: TUmbracoNode[]) => {
    set({ stepNodes: nodes });
  },
});

const useUmbracoStore = create<TUmbracoStore>(devtools(store, { name: 'umbracoStore' }));

export default useUmbracoStore;
