import { EducationLevel } from '@ingeniorforeningen/uidata-client';
import { TSelectOption } from '../types';
import fieldTexts from './local/fields.json';
import paragraphTexts from './local/paragraphs.json';
import months from './local/months.json';
import errorMessages from './local/errorMessages.json';
import levels from './local/levels.json';
import { TFieldText, TFieldTexts, TMonths } from './types';
import useMemberStore from '../store/member/memberStore';
import { capitalize, underscoreToCamelCase } from '../utilities';
import { TUmbracoGiftsFormItem } from '../store/umbraco/types';
import track from '../tracking';

export interface categorizedError {
  label: string;
  errors: string[];
}

export const getLevelText = (educationLevel: EducationLevel): string => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const levelTexts: { [key: string]: { [lang: string]: string } } = levels;

  const selectedLevel = levelTexts[educationLevel.id as string];
  if (selectedLevel) {
    return selectedLevel[language];
  }

  track.exception({ errorcode: 500, id: `Level ${educationLevel.id} not found in levels.json` });

  return educationLevel.name || '';
};

export const getFieldText = (field: string): TFieldText => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const text: TFieldTexts = fieldTexts;
  return text[language as string][field] || '';
};

export const getParagraphText = (paragraph: string): string => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const text: { [lang: string]: { [key: string]: string } } = paragraphTexts;
  return text[language as string][paragraph] || '';
};

export const getMonths = (): TSelectOption[] => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const monthNames: TMonths = months;
  const result: TSelectOption[] = [];

  monthNames[language as string].forEach((month, index) => {
    result.push({
      label: `${capitalize(month)}`,
      value: JSON.stringify(index + 1),
    });
  });

  return result;
};

export const getYears = (minYear?: number, maxYear?: number): TSelectOption[] => {
  const currentYear = maxYear || new Date().getFullYear() + 10;
  const startYear = (minYear && minYear - 1) || 1920;
  const years = [...Array(currentYear - startYear).keys()].map((i) => ({
    value: (currentYear - i).toString(),
    label: (currentYear - i).toString(),
  }));

  return years;
};

export const getErrorMessage = (error: string): string => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const messages = errorMessages[language as keyof typeof errorMessages];
  const type = error?.replace(`${error?.split('_').shift()}_`, '');

  if (type) {
    const formattedType = underscoreToCamelCase(type);
    if (messages[formattedType as keyof typeof messages]) {
      return messages[formattedType as keyof typeof messages];
    }
  }
  return messages['isInvalid' as keyof typeof messages];
};

const getErrorMessagesPlural = (error: string): string => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const messages = errorMessages[language as keyof typeof errorMessages];
  const type = error?.replace(`${error?.split('_').shift()}_`, '');

  if (type) {
    const formattedType = `${underscoreToCamelCase(type)}_plural`;
    if (messages[formattedType as keyof typeof messages]) {
      return messages[formattedType as keyof typeof messages];
    }
  }
  return messages['isInvalid_plural' as keyof typeof messages];
};

/**
 * Groups errors based on error message and maps to list of categorized errors
 * @param errors: list of validator's errors
 */
export const getCategorizedErrorMessages = (errors: [string, string][]): categorizedError[] => {
  const language = useMemberStore.getState().member.config.languageId.toLowerCase();
  const text: TFieldTexts = fieldTexts;
  let localizedText = text[language as keyof typeof text];
  localizedText = Object.keys(localizedText).reduce(
    (prev, current) => ({ ...prev, [current.toLowerCase()]: localizedText[current] }),
    {},
  );

  const errorsWithCategory = errors.map((err) => ({
    inputLabel: err[0],
    category: getErrorMessagesPlural(err[1]),
  }));

  const categories = errorsWithCategory?.map((err) => err.category);
  const uniqueCategories = [...new Set(categories)]; // We don't want duplicated categories
  const categorizedErrors = [] as categorizedError[];
  uniqueCategories.forEach((category) => {
    categorizedErrors.push({
      label: category,
      errors: errorsWithCategory
        .filter((err) => err.category === category)
        .map((err) => {
          const property = localizedText[err.inputLabel as string];
          return property?.errorLabel || property?.label.replace('*', '') || err.inputLabel;
        }),
    });
  });
  return categorizedErrors;
};

export const getExtendedField = (field: string): { name: string; label: string } | null => {
  if (field.endsWith('Month')) {
    return { name: `${field}Name`, label: getFieldText(field)?.label?.replace('*', '') };
  }
  if (field.endsWith('Id')) {
    return {
      name: field.replace('Id', 'Name'),
      label: getFieldText(field)?.label?.replace('*', ''),
    };
  }
  return null;
};

export const removeLinksFromItems = (items: TUmbracoGiftsFormItem[]): TUmbracoGiftsFormItem[] => {
  const itemsWithoutlinks = items.map((item) => {
    const { ...itemWithoutlinks } = item;
    return itemWithoutlinks;
  });
  return itemsWithoutlinks;
};
