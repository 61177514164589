import { createStyles } from '@ingeniorforeningen/figurine-core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    [theme.fn.largerThan('sm')]: {
      justifyContent: 'center',
    },
  },
}));