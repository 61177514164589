import { TAreaName } from '../store/member/types';
import { TUmbracoNode } from '../store/umbraco/types';

export type TStepProps = {
  area: TAreaName;
  content?: TUmbracoNode;
};

export type TComponentProps = {
  area: TAreaName;
  feedback?: (isValid: boolean) => void;
};
export type TDynamicComponentProps = {
  step: number;
  area?: string;
};

export type TLimits = {
  [key: string]: {
    min: number;
    max: number;
  };
};

export type TEventChange = {
  type: string;
  value: string;
  name: string;
  event: React.ChangeEvent<HTMLInputElement>;
};

export type TEventFocus = {
  type: string;
  name: string;
  event: React.FocusEvent<HTMLInputElement>;
};

export type TEventSelectChange = {
  type: string;
  value: string | string[];
  name: string;
  event?: React.ChangeEvent<HTMLInputElement>;
};

export type TSelectOption = {
  label: string;
  value: string;
  description?: string;
};

export type TLevels = {
  [name: string]: TSelectOption[];
};

export type TSituation = {
  id: string;
  name: string;
  value: string;
  dateType?: string;
  hasJob: boolean;
};

export type TValid = {
  valid: boolean;
  messageId: string;
};

export type TReviewData = {
  [field: string]: {
    [field: string]: string;
  };
};

export const skipGiftValue = '-';
