import React from 'react';
import { Grid } from '@ingeniorforeningen/figurine-core';
import shallow from 'zustand/shallow';
import { useCountries } from 'src/data/personal';
import { TextField, Select } from '../../inputs';
import { TAreaName } from '../../../store/member/types';
import { PersonalExtended } from '../../../store/member/areas/PersonalExtended';
import { JobExtended } from '../../../store/member/areas/JobExtended';
import useMemberStore from '../../../store/member/memberStore';

type TProps = {
  area: TAreaName;
};

type TAreaWithAddress = {
  personal: PersonalExtended;
  job: JobExtended;
};

const Address: React.FC<TProps> = ({ area }): JSX.Element => {
  const { data: countries } = useCountries();

  const { countryId, cityName } = useMemberStore(
    (state) => ({
      countryId: state.member[area as keyof TAreaWithAddress].countryId,
      cityName: state.member[area as keyof TAreaWithAddress].cityName,
    }),
    shallow,
  );

  return (
    <Grid item xs={12}>
      <Grid container columns={12} spacing="xl" mb="lg">
        <Grid item xs={12} sm={6} lg={4}>
          <Select field="countryId" options={countries || []} area={area} searchable />
        </Grid>
      </Grid>

      {countryId === runtimeConfig.denmarkId ? (
        <Grid container columns={12} spacing="xl">
          <Grid item xs={12} sm={6} lg={4}>
            <TextField field="street" area={area} required key="denmarkStreet" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              field="postalDistrictId"
              type="number"
              area={area}
              required
              key="denmarkPostal"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              field="cityId"
              area={area}
              disabled
              required
              key="denmarkCity"
              lockedValue={cityName}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container columns={12} spacing="xl">
          <Grid item xs={12} lg={4}>
            <TextField field="street" area={area} key="otherStreet" />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField field="street2" area={area} key="otherStreet2" />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField field="street3" area={area} key="otherStreet3" />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Address;
