import React, { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { AppShell, THeaderProps } from '@ingeniorforeningen/figurine-core';
import { AuthProvider } from './components/authentication/AuthProvider';
import PrivateRoute from './components/authentication/PrivateRoute';
import LoginCallback from './components/authentication/LoginCallback';
import LogoutCallback from './components/authentication/LogoutCallback';
import SilentRenew from './components/authentication/SilentRenew';
import Logout from './components/authentication/Logout';
import RouteResolver from './routes/RouteResolver';
import { queryClient } from './data/clients';
import { setLeadIdInState } from './tracking/leads';
import OnRouteChange from './routes/OnRouteChange';

const App: React.FC = () => {
  useLayoutEffect(() => {
    // Fetch LeadId or uid from url and set in app
    setLeadIdInState();
  }, []);

  const headerData: THeaderProps = {
    showRegister: true,
    search: {
      href: 'https://ida.dk/soeg',
      text: 'Søg',
    },
    languages: [
      { code: 'da', href: '/' },
      { code: 'en', href: '/en' },
    ],
    globalLinks: [
      {
        text: 'IDA.DK',
        href: 'https://ida.dk',
      },
      {
        text: 'IDA Forsikring',
        href: 'https://idaforsikring.dk',
      },
    ],
    localLinks: [],
  };

  return (
    <AppShell headerData={headerData} scrollToTop={false}>
      <Router>
        <OnRouteChange />
        <AuthProvider
          login={{
            client_id: runtimeConfig.clientId || '',
            authority: runtimeConfig.authority || '',
          }}
        >
          <QueryClientProvider client={queryClient}>
            <Routes>
              {/* Login related pages */}
              <Route path="/signin-oidc" element={<LoginCallback />} />
              <Route path="/signout-callback-oidc" element={<LogoutCallback />} />
              <Route path="/silent-renew-oidc" element={<SilentRenew />} />
              <Route path="/logout" element={<PrivateRoute element={Logout} />} />
              {/* End Login related pages */}

              {/* We don't know the routes yet, so we have to create a 
                nested routeResolver that catches all routing */}
              <Route path="*" element={<RouteResolver />} />
            </Routes>
          </QueryClientProvider>
        </AuthProvider>
      </Router>
    </AppShell>
  );
};

export default App;
