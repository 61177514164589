import { Situation } from '@ingeniorforeningen/member-signup-backend-client';
import TrackingManager from '@ingeniorforeningen/tracking';
import { convertSituationToTitle, convertPositionToTitle, convertStepToTitle } from './helpers';

import {
  TMemberTypeTrack,
  TPageViewTrack,
  TSignUpTrack,
  TExceptionTrack,
  TGoBackTrack,
  TCompletedTrack,
  TFormsTrack,
  TGoForwardTrack,
  TRequireAssistanceTrack,
  TGiftChosenTrack,
} from '../types';

import { TAreaName } from '../../store/member/types';

const empty = 'ingen værdi';

const useGtmProdContainer = !!runtimeConfig.useGtmProdContainer;
const trackingManager = new TrackingManager(useGtmProdContainer);

const translateGiftLabel = (text?: string) => {
  if (text) {
    return text === '-' ? 'sprunget over' : text;
  }
  return empty;
};

declare global {
  interface Window {
    // because this is third party (Google) I wont bother
    // coming up with a fancy solution for not using "any"
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

window.dataLayer = window.dataLayer || [];

const marketing = {
  exception: ({ id, errorcode }: TExceptionTrack): void => {
    window.dataLayer.push({
      event: 'trackFejlbesked',
      fejlkode: errorcode,
      fejlbeskrivelse: id,
    });
  },
  pageView: ({ isLoggedIn, nodeId, pageTitle, path }: TPageViewTrack): void => {
    trackingManager.page.view({
      section: 'indmeld',
      pageTitle: path === '/' || path === '/en' ? 'forside' : pageTitle,
      isLoggedIn,
      nodeId,
    });
  },
  memberType: ({ situation, leadId, language }: TMemberTypeTrack): void => {
    window.dataLayer.push({
      event: 'trackIndmeld',
      indmeldHandling: 'indmeld kundetype valgt',
      indmeldMedlemstypeValgt: convertSituationToTitle(situation),
      sprog: language.toLowerCase(),
      kundetype: situation === Situation.Student ? 'studerende' : 'kandidat',
      stepnavn: 'start din indmeldelse i ida her',
      leadId,
      ledelse: empty,
      gave: empty,
    });
  },
  signUp: ({ language, leadId }: TSignUpTrack): void => {
    window.dataLayer.push({
      event: 'trackIndmeld',
      indmeldHandling: 'indmeld startet',
      leadId,
      sprog: language,
      stepnavn: 'start din indmeldelse i ida her',
      kundetype: empty,
      ledelse: empty,
      gave: empty,
    });
  },
  giftChosen: ({
    stepName,
    situation,
    leadership,
    language,
    leadId,
    gift,
  }: TGiftChosenTrack): void => {
    window.dataLayer.push({
      event: 'trackIndmeld',
      indmeldHandling: 'indmeld gave valgt',
      stepnavn: stepName.toLowerCase(),
      kundetype: situation === Situation.Student ? 'studerende' : 'kandidat' || empty,
      ledelse: leadership || empty,
      sprog: language,
      leadId,
      gave: translateGiftLabel(gift),
    });
  },
  goForward: ({
    stepName,
    situation,
    leadership,
    language,
    leadId,
    gift,
  }: TGoForwardTrack): void => {
    window.dataLayer.push({
      event: 'trackIndmeld',
      indmeldHandling: 'indmeld frem',
      stepnavn: stepName.toLowerCase(),
      kundetype: situation === Situation.Student ? 'studerende' : 'kandidat' || empty,
      ledelse: leadership || empty,
      sprog: language,
      leadId,
      gave: translateGiftLabel(gift),
    });
  },
  goBack: ({ stepName, situation, leadership, language, leadId, gift }: TGoBackTrack): void => {
    window.dataLayer.push({
      event: 'trackIndmeld',
      indmeldHandling: 'indmeld tilbage',
      stepnavn: stepName.toLowerCase(),
      kundetype: situation === Situation.Student ? 'studerende' : 'kandidat' || empty,
      ledelse: leadership || empty,
      sprog: language,
      leadId,
      gave: translateGiftLabel(gift),
    });
  },
  completed: ({
    stepName,
    situation,
    leadership,
    language,
    leadId,
    consent,
    gift,
  }: TCompletedTrack): void => {
    window.dataLayer.push({
      event: 'trackIndmeld',
      indmeldHandling: 'indmeld gennemført',
      stepnavn: stepName.toLowerCase(),
      kundetype: situation === Situation.Student ? 'studerende' : 'kandidat' || empty,
      ledelse: leadership || empty,
      sprog: language,
      leadId,
      samtykke: consent ? 'samtykke givet' : 'samtykke fjernet',
      gave: translateGiftLabel(gift),
    });
  },
  forms: {
    started: ({ name }: TFormsTrack): void => {
      trackingManager.forms.started({ name });
    },
    completed: ({ name }: TFormsTrack): void => {
      trackingManager.forms.completed({ name });
    },
  },
  requireAssistance: ({
    situation,
    pageTitle,
    position,
    language,
    leadId,
    reason,
    gift,
  }: TRequireAssistanceTrack): void => {
    window.dataLayer.push({
      event: 'trackIndmeld',
      indmeldHandling: 'indmeld kontakt os',
      stepnavn: convertStepToTitle(pageTitle.toLowerCase() as TAreaName),
      kundetype: situation === Situation.Student ? 'studerende' : 'kandidat',
      sprog: language.toLowerCase(),
      leadId,
      indmeldAarsag: reason,
      ledelse: convertPositionToTitle(position),
      gave: translateGiftLabel(gift),
    });
  },
};

export default marketing;
