import developer from './developer';
import marketing from './marketing';
import {
  TExceptionTrack,
  TMemberTypeTrack,
  TSignUpTrack,
  TPageViewTrack,
  TGoBackTrack,
  TCompletedTrack,
  TFormsTrack,
  TGoForwardTrack,
  TRequireAssistanceTrack,
  TGiftChosenTrack,
  TEventTrack
} from './types';

const track = {
  pageView: (props: TPageViewTrack): void => {
    marketing.pageView(props);
    developer.pageView(props);
  },
  requireAssistance: (props: TRequireAssistanceTrack): void => {
    marketing.requireAssistance(props);
  },
  memberType: (props: TMemberTypeTrack): void => {
    marketing.memberType(props);
  },
  signup: (props: TSignUpTrack): void => {
    marketing.signUp(props);
  },
  giftChosen: (props: TGiftChosenTrack): void => {
    marketing.giftChosen(props);
  },
  exception: (props: TExceptionTrack): void => {
    marketing.exception(props);
    developer.exception(props);
  },
  goForward: (props: TGoForwardTrack): void => {
    marketing.goForward(props);
  },
  goBack: (props: TGoBackTrack): void => {
    marketing.goBack(props);
  },
  completed: (props: TCompletedTrack): void => {
    marketing.completed(props);
  },
  forms: {
    started: (props: TFormsTrack): void => {
      marketing.forms.started(props);
    },
    completed: (props: TFormsTrack): void => {
      marketing.forms.completed(props);
    },
  },
  event: (props: TEventTrack): void => {
    developer.event(props)
  }
};

export default track;
