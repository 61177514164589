import {
  Button,
  Carousel,
  Container,
  Group,
  Modal,
  OnlyShow,
  Paragraph,
  createStyles,
  useLanguage,
  useLocaleText,
} from '@ingeniorforeningen/figurine-core';
import { Cards, Card, Richtext } from '@ingeniorforeningen/figurine-cms';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import shallow from 'zustand/shallow';
import { useUmbraco, useUmbracoGifts } from 'src/data/umbraco';
import { TUmbracoDictionaryResult } from 'src/data/types';
import { GiftType } from 'src/store/member/areas/GiftExtended';
import { getParagraphText } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';
import useUiStore from '../../store/ui/uiStore';
import { TUmbracoGiftsForm } from '../../store/umbraco/types';
import useUmbracoStore from '../../store/umbraco/umbracoStore';
import { skipGiftValue, TStepProps } from '../../types';
import track from '../../tracking';

const styles = createStyles(() => ({
  root: {
    width: '64.5em',
    margin: 'auto',
  },
}));

const Gift: React.FC<TStepProps> = ({ area }) => {
  const steps = useUmbracoStore((state) => state.stepNodes);
  const { language } = useLanguage();
  const { data: giftPageData } = useUmbraco(runtimeConfig.giftPageGuid, language);
  const { data: giftsData } = useUmbracoGifts(runtimeConfig.giftsGuid, language);
  const { classes } = styles();
  const [chosen, setChosen] = useState<{ name: string; id: string; physical: boolean; donation: boolean } | null>(
    null,
  );
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const texts = useLocaleText();

  const { currentStep, updateUi } = useUiStore(
    (state) => ({
      currentStep: state.currentStep,
      updateUi: state.updateUi,
    }),
    shallow,
  );

  const { situation, position, lang, leadId, selectedGift, updateMember } = useMemberStore(
    (state) => ({
      situation: state.member.job.situationId,
      position: state.member.job.positionName,
      lang: state.member.config.languageId,
      leadId: state.member.config.leadId,
      selectedGift: state.member.gift.selectedGift,
      updateMember: state.updateMember,
    }),
    shallow,
  );

  const calcGiftType = (physical: boolean | undefined, donation: boolean | undefined) => {
    if (physical) {
      return GiftType.physical;
    }
    if (donation) {
      return GiftType.donation;
    }
    if (physical === false && donation === false) {
      return GiftType.digital;
    }
    return GiftType.none;
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight / 2,
        behavior: 'smooth',
      });
    }, 250);
  };

  const resetChosen = () => {
    setChosen(null);
    setOpenDisclaimer(false);
    updateMember([
      { property: 'campaignId', value: '', areaName: area },
      { property: 'selectedGift', value: '-', areaName: area },
      { property: 'type', value: GiftType.none, areaName: area },
    ]);
  };

  const readyToProceed = () => {
    setOpenDisclaimer(false);
    scrollToBottom();
    updateMember([
      { property: 'campaignId', value: chosen?.id, areaName: area },
      { property: 'selectedGift', value: chosen?.name, areaName: area },
      { property: 'type', value: calcGiftType(chosen?.physical, chosen?.donation), areaName: area },
    ]);
  };

  useEffect(() => {
    updateUi('area', area);
  }, []);

  useEffect(() => {
    if (!steps) {
      return;
    }
    const step = steps[0] as TUmbracoGiftsForm;
    updateMember([
      { property: 'campaignCode', value: step.campaignCode, areaName: area },
      { property: 'selectedGift', value: skipGiftValue, areaName: area },
    ]);
  }, [steps]);

  useEffect(() => {
    track.giftChosen({
      stepName: steps[currentStep - 1].name as string,
      situation,
      leadership: position,
      language: lang.toLowerCase(),
      leadId,
      gift: chosen?.name,
    });

    if (chosen?.name === selectedGift) {
      resetChosen();
    } else if (chosen) {
      if (chosen.physical) {
        setOpenDisclaimer(true);
      } else {
        readyToProceed();
      }
    }
  }, [chosen]);

  return (
    <>
      <Helmet>
        <title>{getParagraphText('gift')}</title>
      </Helmet>
      {giftsData && giftsData.length > 0 && (
        <>
          <OnlyShow smallerThan="lg">
            <Container>
              <Carousel slideSize="20.375em" draggable>
                {giftsData.map((gift: TUmbracoDictionaryResult) => (
                  <Card
                    key={`${gift.header?.value}sm`}
                    name={gift.header?.value as string}
                    variant="selectable"
                    value={chosen?.name === gift.header?.value}
                    onClick={() =>
                      setChosen({
                        name: gift.header?.value as string,
                        id: gift.campaignId?.value as string,
                        physical: gift.physical?.value as boolean,
                        donation: gift.donation?.value as boolean,
                      })
                    }
                    header={gift.header?.value as string}
                    image={{
                      src: runtimeConfig.umbraco + (gift.image?.value?.url as string),
                    }}
                  />
                ))}
              </Carousel>
            </Container>
          </OnlyShow>
          <OnlyShow largerThan="lg">
            <div>
              <Cards
                className={classes.root}
                fluid={false}
                cypress="giftMultiBox"
                items={giftsData.map((gift: TUmbracoDictionaryResult) => ({
                  cypress: 'gift-card',
                  name: gift.header?.value as string,
                  variant: 'selectable',
                  value: chosen?.name === gift.header?.value,
                  onClick: () =>
                    setChosen({
                      name: gift.header?.value as string,
                      id: gift.campaignId?.value as string,
                      physical: gift.physical?.value as boolean,
                      donation: gift.donation?.value as boolean,
                    }),
                  header: gift.header?.value as string,
                  image: {
                    src: runtimeConfig.umbraco + (gift.image?.value?.url as string),
                  },
                }))}
              />
            </div>
          </OnlyShow>
        </>
      )}

      {giftPageData && <Richtext html={giftPageData?.giftsPage?.terms?.value || ''} mt="md" />}
      <Modal
        opened={openDisclaimer}
        onClose={readyToProceed}
        title={giftPageData?.giftsPage?.physicalGiftInformation?.header?.value || ''}
      >
        <Paragraph
          text={giftPageData?.giftsPage?.physicalGiftInformation?.description?.value || ''}
        />
        <Group mt="xl" justify="center">
          <Button text={texts.no} variant="outline" onClick={resetChosen} />
          <Button text={texts.yes} onClick={readyToProceed} />
        </Group>
      </Modal>
    </>
  );
};

export default Gift;
