import React from 'react';
import { Grid } from '@ingeniorforeningen/figurine-core';
import { TextField } from '../inputs';
import { TComponentProps } from '../../types';

const AbroadEducation = ({ area }: TComponentProps): JSX.Element => (
  <Grid container columns={12} spacing="xl">
    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="abroadInstitution" area={area} required />
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="abroadSpecialization" area={area} required />
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <TextField field="abroadLevel" area={area} required />
    </Grid>
  </Grid>
);
export default AbroadEducation;
