import { GetConsentDefinitionResponse } from "@ingeniorforeningen/consent-client";

export type TUiStore = {
  isInitialized: boolean;
  currentStep: number;
  area: string;
  view: string;
  formConfirmed: boolean;
  showDevControls: boolean;
  uniqueTrackings: string[];
  errors: Record<string, unknown>;
  cprConsentInfo: GetConsentDefinitionResponse;
  marketingConsentInfo: GetConsentDefinitionResponse;
  updateUi: (property: keyof TUiStore, value: unknown) => void;
};

// eslint-disable-next-line no-shadow
export enum UiStep {
  gift = 'gift',
  education = 'education',
  employment = 'employment',
  personal = 'personal',
  confirmation = 'confirmation',
}

export type TUiProperty = {
  name: keyof TUiStore;
  value: unknown;
};
