import { Gift, IGift } from '@ingeniorforeningen/member-signup-backend-client';

export enum GiftType {
  none = 'none',
  physical = 'physical',
  digital = 'digital',
  donation = 'donation',
}

export interface IGiftExtended extends IGift {
  type: GiftType;
}

export class GiftExtended extends Gift {
  type: GiftType;

  constructor(data: IGiftExtended) {
    super(data);
    this.type = data.type;
  }
}
