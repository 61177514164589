/* eslint-disable no-param-reassign */
import { Situation } from '@ingeniorforeningen/member-signup-backend-client';
import { produce } from 'immer';
import { GetState } from 'zustand';
import { NamedSet } from 'zustand/middleware';
import track from '../../tracking';
import { SignupExtended } from './areas/SignupExtended';
import { Filters, TFilter } from './filters';
import { memberInitialState } from './initialState';
import { TMemberProperties, TMemberStore, TUpdateMember } from './types';

export const handleSetSituation = (set: NamedSet<TMemberStore>, situation: Situation): void => {
  const isStudent = situation === Situation.Student;
  const updatedSituation = situation === Situation.Employed ? Situation.Unknown : situation;
  set(
    produce((state) => {
      state.member.education.isStudent = isStudent;
      state.member.job.situationId = updatedSituation;
      state.member.job.situationName = updatedSituation;
    }),
  );
};

export const handleUpdatingMember = (
  set: NamedSet<TMemberStore>,
  member: SignupExtended,
  properties: TUpdateMember[],
): void => {
  let newMember = member;

  properties.forEach((field) => {
    const area = newMember[field.areaName];
    newMember = new SignupExtended({
      ...newMember,
      [field.areaName]: {
        ...area,
        [field.property]: field.value,
      },
    });
  });

  set({ member: newMember });
};

export function handleUnusedProperties(
  set: NamedSet<TMemberStore>,
  get: GetState<TMemberStore>,
): void {
  try {
    const currentMember = get().member;
    let updatedMember = currentMember;

    Filters.forEach((filter: TFilter) => {
      const area = currentMember[filter.area];
      const propertyValue = area[filter.reference as keyof typeof area];

      const conditions = filter.isEqual(propertyValue) ? filter.include : filter.exclude;

      conditions.forEach((condition: TMemberProperties) => {
        try {
          const initialArea = memberInitialState[filter.area];
          const initialValue = initialArea[condition as keyof typeof initialArea];
          area[condition as keyof typeof area] = initialValue;
        } catch (err) {
          track.exception({
            errorcode: 400,
            id: "Property for the condition couldn't be found",
            error: err as Error,
          });
        }
      });

      updatedMember = new SignupExtended({ ...currentMember, [filter.area]: area });
    });

    set({ member: updatedMember });
  } catch (err) {
    track.exception({
      errorcode: 500,
      id: 'An error happened in filtering unused member data',
      error: err as Error,
    });
  }
}
