import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid } from '@ingeniorforeningen/figurine-core';
import { getParagraphText } from '../../data/helpers';
import useUiStore from '../../store/ui/uiStore';
import { TStepProps } from '../../types';
import { translate } from '../../utilities';
import Consent from '../inputGroups/Consent';
import DataReview from '../inputGroups/DataReview';
import Membership from '../inputGroups/Membership';

const Confirmation: React.FC<TStepProps> = ({ area, content }) => {
  const updateUi = useUiStore((state) => state.updateUi);

  useEffect(() => {
    updateUi('area', area);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{getParagraphText('confirmation')}</title>
      </Helmet>
      <Grid container spacing="lg">
      <DataReview />
      <Membership />
      <Consent
        lawsAndStatutes={content ? translate(content, 'lawsAndStatutes') : ''}
        privacyPolicy={content ? translate(content, 'privacyPolicy') : ''}
      />
      </Grid>
    </Container>
  );
};

export default Confirmation;
