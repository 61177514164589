import { useQuery } from '@tanstack/react-query';
import useMemberStore from 'src/store/member/memberStore';
import { consentClient } from './clients';

export const useConsent = (consentName: string) => {
  const language = useMemberStore((state) => state.member.config.languageId);

  return useQuery({
    queryKey: ['consent', consentName],
    queryFn: async () =>
      consentClient.getConsentDefinition(consentName, 'latest', language.toLowerCase()),
    select: (data) => data.result,
  });
};
