import create from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';
import { GetConsentDefinitionResponse } from '@ingeniorforeningen/consent-client';
import { handleUiUpdate } from './helpers';
import { TUiStore } from './types';

const store = (set: NamedSet<TUiStore>) => ({
  isInitialized: false,
  currentStep: 1,
  area: 'personal',
  view: 'form',
  formConfirmed: false,
  showDevControls: false,
  errors: {},
  uniqueTrackings: [] as string[],
  cprConsentInfo: new GetConsentDefinitionResponse({
    consentName: undefined,
    title: undefined,
    text: undefined,
    additionalText: undefined,
    version: undefined,
  }),
  marketingConsentInfo: new GetConsentDefinitionResponse({
    consentName: undefined,
    title: undefined,
    text: undefined,
    additionalText: undefined,
    version: undefined,
  }),
  updateUi: (property: keyof TUiStore, value: unknown) => {
    handleUiUpdate(set, property, value);
  },
});

const useUiStore = create<TUiStore>(devtools(store, { name: 'uiStore' }));
export default useUiStore;
