import { TSelectFieldItem } from '@ingeniorforeningen/figurine-core';
import { useQuery } from '@tanstack/react-query';
import { queryClient, uiDataClient } from './clients';

export const useCountries = () =>
  useQuery({
    queryKey: ['countries'],
    queryFn: async () => uiDataClient.getCountries(),
    staleTime: Infinity,
    select: (data): TSelectFieldItem[] =>
      data &&
      data.map((country) => ({
        value: country.id!,
        label: country.name,
      })),
  });

export const usePostalDistricts = (country: string, postal: string) =>
  useQuery({
    queryKey: ['postalDistricts', postal],
    queryFn: async () => uiDataClient.getPostalDistricts(country, postal),
    staleTime: Infinity,
    select: (data): TSelectFieldItem[] =>
      data &&
      data.map((district) => ({
        value: district.id!,
        label: district.name,
      })),
  });

// This is to be used places where hooks are not available
export const getPostalDistricts = async (country: string, postal: string) =>
  queryClient.fetchQuery({
    queryKey: ['postalDistricts', postal],
    queryFn: async () => uiDataClient.getPostalDistricts(country, postal),
  });
