import { Container, Grid } from '@ingeniorforeningen/figurine-core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { getParagraphText } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';
import useUiStore from '../../store/ui/uiStore';
import { TStepProps } from '../../types';
import BasicInfo from '../inputGroups/BasicInfo';
import CprInfo from '../inputGroups/CprInfo';
import Address from '../inputGroups/dynamic/Address';

const ContactInformation: React.FC<TStepProps> = ({ area }): JSX.Element => {
  const updateUi = useUiStore((state) => state.updateUi);
  const useCprNo = useMemberStore((state) => state.member.personal.useCprNo);

  useEffect(() => {
    updateUi('area', area);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{getParagraphText('personal')}</title>
      </Helmet>
      <Grid container columns={12} spacing="xl">
        <BasicInfo area={area} />
        <CprInfo area={area} />
        {!useCprNo && <Address area={area} />}
      </Grid>
    </Container>
  );
};

export default ContactInformation;
