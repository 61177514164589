/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Group,
  Container,
  Grid,
  Carousel,
  Skeleton,
} from '@ingeniorforeningen/figurine-core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getParagraphText } from '../../../data/helpers';
import { useStyles } from './styles';

const LoadingForm: React.FC = () => {
  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <title>{getParagraphText('subHeader')}</title>
      </Helmet>

      <Group className={classes.background} direction="column">
        <Breadcrumbs
          variant="light"
          homeHref={runtimeConfig.idadkDomain as string}
          items={[
            {
              label: 'Bliv Medlem',
              link: `${runtimeConfig.idadkDomain}/bliv-medlem`,
            },
          ]}
        />
        <Container>
          <Grid container className={classes.textWrapper} mb="xl">
            <Grid item justifyItems="center">
              <Skeleton width="20em" height="4em" />
            </Grid>
            <Grid item justifyItems="center">
              <Skeleton width="15em" height="2em" />
            </Grid>
          </Grid>

          <Group className={classes.cardsWrapper}>
            <Carousel>
              <Skeleton height="30em" width="20em" />
              <Skeleton height="30em" width="20em" />
              <Skeleton height="30em" width="20em" />
            </Carousel>
          </Group>
        </Container>
      </Group>
    </>
  );
};

export default LoadingForm;
