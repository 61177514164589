import { UiStep } from '../ui/types';

// Be aware! A tight coupling exists between UiStep name and the StepNumber in Umbraco
export const umbracoStepNumberToUiStepMap = [
  { stepNumber: 1, uiStep: UiStep.gift },
  { stepNumber: 2, uiStep: UiStep.education },
  { stepNumber: 3, uiStep: UiStep.employment },
  { stepNumber: 4, uiStep: UiStep.personal },
  { stepNumber: 5, uiStep: UiStep.confirmation },
];

// eslint-disable-next-line no-shadow
export enum TUmbracoTemplates {
  membersignupForm = 'membersignupForm',
  membersignupFormStep = 'membersignupFormStep',
  membersignupFrontpage = 'membersignupFrontpage',
  membersignupRecieptPage = 'membersignupRecieptPage',
  membersignupTechnicalPage = 'membersignupTechnicalPage',
  featureFlag = 'featureFlag',
  globalTexts = 'membersignupGlobalTexts',
  membersignupFormGifts = 'membersignupGiftStep',
}

export type TUmbracoNode = Record<string, unknown>;

export type TFeatureFlags = {
  [name: string]: boolean;
};

export type TLocalization = {
  [name: string]: string;
};

export type TContactInfo = {
  contactInfoCallToActionText: TLocalization;
  contactInfoCallToActionLink: TLocalization;
  contactInfoText: TLocalization;
};

export type TAkaInfo = {
  signupHeadline: TLocalization;
  currentUnemploymentInsuranceFund: TLocalization;
  ctaText: TLocalization;
  terms: TLocalization;
  termsHeadline: TLocalization;
  termsLabelText: TLocalization;
  signupSuccessfulHeadline: TLocalization;
  signupSuccessfulDescription: TLocalization;
  signupDescription: TLocalization;
};

export type TUmbracoGiftsFormItem = {
  title: string;
  tagline: string;
  description: string;
  image: string;
  theme: 'blue' | 'white' | 'gray' | undefined;
  link?: {
    url: string;
    target: string;
  };
};

type TUmbracoGiftsFormValueClass = {
  showInMenu?: boolean;
  menuHash?: string;
  menuTitle?: string;
  headline?: string;
  heading?: string;
  showAllHeading?: string;
  showAllUrl?: string;
  hash?: string;
  items?: TUmbracoGiftsFormItem[];
};

export type TUmbracoGiftsFormControl = {
  value: TUmbracoGiftsFormValueClass | string;
  editor: {
    name: string;
    alias: string;
    view: string;
    render: null;
    icon: string;
    config: {
      width?: string;
    };
  };
  active: boolean;
};

export type TUmbracoGiftsForm = TUmbracoNode & {
  campaignCode: string;
  gridGift: {
    name: string;
    sections: {
      grid: number;
      rows: {
        name: string;
        areas: {
          grid: number;
          allowAll: boolean;
          allowed: string[];
          maxItems: number;
          hasConfig: boolean;
          controls: TUmbracoGiftsFormControl[];
          hasActiveChild: boolean;
          active: boolean;
        }[];
        hasConfig: boolean;
        id: string;
        hasActiveChild: boolean;
        active: boolean;
      }[];
    }[];
  };
};

export type TUmbracoStore = {
  contactInfo: TContactInfo;
  akaInfo: TAkaInfo;
  featureFlags: TFeatureFlags;
  stepNodes: TUmbracoNode[];
  currentNode: TUmbracoNode;
  receiptNode: TUmbracoNode;
  setCurrentNode: (nodes: TUmbracoNode[], path: string) => void;
  setReceiptNode: (reciepts: TUmbracoNode[], isStudent: boolean, abroadEducation: boolean) => void;
  setStepNodes: (nodes: TUmbracoNode[]) => void;
};
