import React, { useLayoutEffect } from 'react';
import { Container, Grid, Paper } from '@ingeniorforeningen/figurine-core';
import {
  ClassicUmbracoGrid,
  Html,
  PageTop,
  TClassicUmbracoGridProps,
} from '@ingeniorforeningen/figurine-cms';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { skipGiftValue } from 'src/types';
import { GiftType } from 'src/store/member/areas/GiftExtended';
import AkasseSignup from '../inputGroups/AkasseSignup';
import { TUmbracoNode } from '../../store/umbraco/types';
import { getParagraphText } from '../../data/helpers';
import useMemberStore from '../../store/member/memberStore';
import useUiStore from '../../store/ui/uiStore';
import { formatUrl } from '../../utilities';
import useUmbracoStore from '../../store/umbraco/umbracoStore';

type TProps = {
  content: TUmbracoNode;
};

const RecieptPage: React.FC<TProps> = ({ content }) => {
  const { isStudent, educationEndsSoon, selectedGift, leadId, giftType } = useMemberStore(
    (state) => ({
      isStudent: state.member.education.isStudent,
      educationEndsSoon: state.member.education.educationEndsSoon,
      selectedGift: state.member.gift.selectedGift,
      giftType: state.member.gift.type,
      leadId: state.member.config.leadId,
    }),
    shallow,
  );
  const currentStep = useUiStore((state) => state.currentStep);
  const umbracoSteps = useUmbracoStore((state) => state.stepNodes);
  const { header, teaser, grid } = content;
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (umbracoSteps) {
      const totalSteps = Object.keys(umbracoSteps).length;

      if (totalSteps > currentStep) {
        navigate(formatUrl('/'), { replace: true });
      }
    }
  }, [umbracoSteps, currentStep]);

  const getGiftText = (type: GiftType) => {
    switch (type) {
      case 'physical':
        return getParagraphText('giftSelectedPickup')
          .replace('{selectedGift}', selectedGift)
          .replace('{leadId}', leadId.substring(Math.max(0, leadId.length - 4)));
      case 'digital':
        return getParagraphText('giftSelectedDigital').replace('{selectedGift}', selectedGift);
      case 'donation':
        return getParagraphText('giftSelectedDonation').replace('{selectedGift}', selectedGift);
      default:
        return '';
    }
  };

  return (
    <>
      <Helmet>
        <title>{getParagraphText('receipt')}</title>
      </Helmet>
      <Container my="xl">
        <Grid container>
          <Grid item>
            <PageTop title={header as string} teaser={teaser as string} variant="center-align" />
          </Grid>
          {selectedGift && selectedGift !== skipGiftValue && giftType !== GiftType.none && (
            <Grid container m="xl">
              <Paper
                variant="secondary"
                cypress="selectedGiftInfoBox"
                style={{
                  maxWidth: '38em',
                  margin: 'auto',
                }}
              >
                <Html html={`<p>${getGiftText(giftType)}</p>`} />
              </Paper>
            </Grid>
          )}
          {isStudent && !educationEndsSoon && (
            <Grid container>
              <AkasseSignup />
            </Grid>
          )}
        </Grid>
      </Container>
      <ClassicUmbracoGrid components={grid as TClassicUmbracoGridProps['components']} />
    </>
  );
};

export default RecieptPage;
