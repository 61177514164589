import { createStyles } from '@ingeniorforeningen/figurine-core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    top: '-60px',
    marginBottom: '-60px',
    [theme.fn.largerThan('md')]: {
      marginBottom: '0px',
    },
  },
  hero: {
    overflow: 'hidden',
    position: 'relative',
    paddingTop: '60px',
  },
  background: {
    background: theme.other.colorTokens.gradients.dark,
    paddingTop: '6.5625em',
    marginTop: '-6.5625em',
    width: '100%',
    overflow: 'hidden',
  },
  textWrapper: {
    position: 'relative',
    display: 'grid',
    justifyItems: 'center',
    paddingLeft: '2em',
    paddingRight: '2em',
    gridTemplateRows: 'max-content max-content',
    textAlign: 'center',
    '& *': {
      color: 'white',
    },
  },
  cardsWrapper: {
    position: 'relative',
    gridTemplateColumns: 'max-content',
    gap: '2em',
    justifyContent: 'center',
    paddingBottom: '8em',
    paddingTop: '2em',

    [theme.fn.largerThan('sm')]: {
      paddingTop: '6em',
    },
    [theme.fn.largerThan('md')]: {
      paddingTop: '8em',
      gridTemplateColumns: 'max-content max-content max-content',
    },
  },
  teaser: {
    maxWidth: '46em',
  },
  skeletonWrapper: {
    width: '100%',
    height: '100%',
  },
}));
