import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { capitalize, useLanguage } from '@ingeniorforeningen/figurine-core';
import useMemberStore from '../store/member/memberStore';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const { setLanguage } = useLanguage();
  const updateMember = useMemberStore((state) => state.updateMember);

  useEffect(() => {
    const lang = window.location.pathname.split('/').filter((x) => x)[0] === 'en' ? 'en' : 'da';

    updateMember([{ property: 'languageId', value: capitalize(lang), areaName: 'config' }]);
    setLanguage(lang);

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return null;
};

export default ScrollToTop;
