import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  TextField as FigTextField,
  TTextFieldProps,
  useDebouncedState,
} from '@ingeniorforeningen/figurine-core';
import shallow from 'zustand/shallow';
import { getFieldText } from '../../data/helpers';
import { useFieldValidation } from '../../validation/validator';
import useMemberStore from '../../store/member/memberStore';
import { TAreaName, TMemberProperties } from '../../store/member/types';

type TInputFieldProps = {
  field: TMemberProperties;
  area: TAreaName;
  formatValue?: (value: string) => string;
  overwriteHelptext?: string;
  disabled?: boolean;
  noLabel?: boolean;
  type?: string;
  required?: boolean;
  lockedValue?: string;
};

const TextField: React.FC<TInputFieldProps> = ({
  field,
  area,
  formatValue,
  overwriteHelptext,
  disabled = false,
  noLabel = false,
  type = 'text',
  required = false,
  lockedValue,
}) => {
  const fieldIsAnId = field.endsWith('Id');
  const [value, setValue] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useDebouncedState('', 300);
  const validation = useFieldValidation(field, area, disabled);
  const updateMember = useMemberStore((state) => state.updateMember, shallow);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.currentTarget.value;

    if (formatValue) {
      val = formatValue(e.currentTarget.value);
    }
    setValue(val);
  };

  useEffect(() => {
    setDebouncedValue(value);
  }, [value]);

  useEffect(() => {
    if (fieldIsAnId) {
      updateMember([
        {
          property: field.replace('Id', 'Name') as TMemberProperties,
          value: debouncedValue,
          areaName: area,
        },
      ]);
    } else {
      updateMember([
        { property: field as TMemberProperties, value: debouncedValue, areaName: area },
      ]);
    }
  }, [debouncedValue]);

  return (
    <FigTextField
      defaultValue={debouncedValue}
      value={lockedValue || value}
      name={field}
      id={field}
      label={!noLabel ? getFieldText(field).label : ''}
      placeholder={getFieldText(field).placeholder}
      description={overwriteHelptext || getFieldText(field)?.helpText}
      onChange={onInputChange}
      error={validation.showError ? validation.message : ''}
      disabled={disabled}
      required={required}
      type={type as TTextFieldProps['type']}
    />
  );
};

export default TextField;
