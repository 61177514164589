import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useConsent } from 'src/data/consent';
import { useUmbracoOld } from 'src/data/umbraco';
import Error from '../components/pages/Error';
import Form from '../components/pages/Form/Form';
import Frontpage from '../components/pages/Frontpage/Frontpage';
import LoadingForm from '../components/pages/Frontpage/LoadingForm';
import RecieptPage from '../components/pages/RecieptPage';
import { TUmbracoTemplates } from '../store/umbraco/types';
import useUmbracoStore from '../store/umbraco/umbracoStore';
import { formatUrl } from '../utilities';
import RouteWrapper from './RouteWrapper';
import track from '../tracking';

const RouteResolver: React.FC = () => {
  const location = useLocation();
  const { data, error, isLoading } = useUmbracoOld();

  const {
    data: marketingConsentData,
    error: marketingConsentError,
    isLoading: marketingLoading,
  } = useConsent('marketing');
  const {
    data: cprConsentData,
    error: cprConsentError,
    isLoading: consentLoading,
  } = useConsent('cpr');

  const { setCurrentNode, currentNode } = useUmbracoStore(
    (state) => ({
      setCurrentNode: state.setCurrentNode,
      currentNode: state.currentNode,
    }),
    shallow,
  );

  if (data && marketingConsentData && cprConsentData) {
    setCurrentNode(data, location.pathname);
  }

  const getTemplateName = (): string => {
    if (error || marketingConsentError || cprConsentError || currentNode.error) {
      track.exception({
        errorcode: 404,
        id: 'Could not resolve page',
        error: error || marketingConsentError || cprConsentError || undefined,
        properties: {
          pathname: location.pathname,
        },
      });

      return 'error';
    }

    if (currentNode.template) {
      return currentNode.template as string;
    }

    return 'goHome';
  };

  const getRoute = () => {
    switch (getTemplateName()) {
      case 'error':
        return <Error />;
      case 'goHome':
        return <Navigate to={formatUrl('/')} replace />;
      case TUmbracoTemplates.membersignupFormStep:
        return <Form content={currentNode} />;
      case TUmbracoTemplates.membersignupFormGifts:
        // This is a Temporary solution until all data is fetched from mini-cms.
        // When that happens we need to rethink this currentNode state solution
        return <Form content={currentNode} />;
      case TUmbracoTemplates.membersignupRecieptPage:
        return <RecieptPage content={currentNode} />;
      case TUmbracoTemplates.membersignupFrontpage:
        return <Frontpage content={currentNode} />;
      default:
        return <Navigate to={formatUrl('/')} replace />;
    }
  };

  return isLoading || consentLoading || marketingLoading ? (
    <LoadingForm />
  ) : (
    <RouteWrapper node={currentNode} location={location}>
      {getRoute()}
    </RouteWrapper>
  );
};

export default RouteResolver;
