import { Container, Grid, Heading, Html, Paper } from '@ingeniorforeningen/figurine-core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationButtons from 'src/components/navigationButtons';
import ProgressIndicator from 'src/components/progressIndicator';
import { useUmbracoOld } from 'src/data/umbraco';
import { getParagraphText } from '../../../data/helpers';
import { extractFormWrapper } from '../../../data/utilities';
import useMemberStore from '../../../store/member/memberStore';
import { TAreaName } from '../../../store/member/types';
import { UiStep } from '../../../store/ui/types';
import useUiStore from '../../../store/ui/uiStore';
import { convertCurrentStepToUiStep, isStepEqualToGiftStep } from '../../../store/umbraco/helpers';
import { TUmbracoNode } from '../../../store/umbraco/types';
import useUmbracoStore from '../../../store/umbraco/umbracoStore';
import { skipGiftValue } from '../../../types';
import { formatUrl, translate } from '../../../utilities';
import Confirmation from '../../steps/Confirmation';
import ContactInformation from '../../steps/ContactInformation';
import Education from '../../steps/Education';
import Employment from '../../steps/Employment';
import Gift from '../../steps/Gift';
import { useStyles } from './styles';

type TProps = {
  content: TUmbracoNode;
};

const Form: React.FC<TProps> = ({ content }) => {
  const navigate = useNavigate();
  const { data: umbracoNodes } = useUmbracoOld();
  const stepNodes = useUmbracoStore((state) => state.stepNodes);
  const currentStep = useUiStore((state) => state.currentStep);
  const formData = extractFormWrapper(umbracoNodes);
  const { refreshMember, selectedGift } = useMemberStore((state) => ({
    refreshMember: state.refreshMember,
    selectedGift: state.member.gift.selectedGift,
  }));
  const { classes } = useStyles();

  const [Component, setComponent] = useState<JSX.Element>();

  const getStep = () => {
    const stepNumber = stepNodes.indexOf(content) + 1;
    const area = (content.area as string).toLowerCase() as TAreaName;

    // This check makes sure that you can only get to the step if the previous is filled out
    if (stepNumber !== currentStep) {
      navigate(formatUrl('/'), { replace: true });
    }

    switch (convertCurrentStepToUiStep(currentStep, stepNodes)) {
      case UiStep.gift:
        return <Gift area={area} />;
      case UiStep.education:
        return <Education area={area} />;
      case UiStep.employment:
        return <Employment area={area} />;
      case UiStep.personal:
        return <ContactInformation area={area} />;
      case UiStep.confirmation:
        return <Confirmation area={area} content={{ ...content, ...formData }} />;
      default:
        return <Education area="education" />;
    }
  };

  useEffect(() => {
    setComponent(getStep());
    refreshMember();
  }, [content]);

  return (
    <>
      <Container mt="xl">
        <Grid container>
          <Grid item>
            <ProgressIndicator />
          </Grid>
        </Grid>
      </Container>
      <Container>
        {selectedGift && isStepEqualToGiftStep(currentStep - 1, stepNodes) && (
          <Grid container m="xl">
            <Paper variant="secondary" cypress="selectedGiftInfoBox" className={classes.chosenGift}>
              {selectedGift === skipGiftValue ? (
                <Html html={getParagraphText('youHaveDeclinedGift')} />
              ) : (
                <Html
                  html={`<p>${getParagraphText('giftSelected').replace(
                    '{selectedGift}',
                    selectedGift,
                  )}</p>`}
                />
              )}
            </Paper>
          </Grid>
        )}
        <Grid container>
          <Grid item className={classes.head} mt="xl" spacing={0}>
            <Heading type="h3" text={content.tagline as string} variant="primary" />
            <Heading type="h1" text={content.header as string} />
          </Grid>
        </Grid>
      </Container>

      <Grid container className={classes.formPages}>
        {Component}
      </Grid>

      <Container>
        <Grid container className={classes.buttons}>
          <Grid item justify="center">
            <NavigationButtons
              nextLabel={translate(formData, 'nextButtonLabel')}
              prevLabel={translate(formData, 'previousButtonLabel')}
              confirmLabel={translate(formData, 'confirmButtonLabel')}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Form;
