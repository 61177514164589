import { createStyles } from '@ingeniorforeningen/figurine-core';

export const useStyles = createStyles(() => ({
  buttons: {
    marginTop: '3em',
  },
  formPages: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  head: {
    marginTop: '2em',
    height: 'auto',
  },
  chosenGift: {
    textAlign: 'center',
  },
}));
