import React, { useState } from 'react';
import { Button, Group, IconButton, OnlyShow } from '@ingeniorforeningen/figurine-core';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { service } from 'src/data/clients';
import { getParagraphText } from 'src/data/helpers';
import { skipGiftValue } from 'src/types';
import { useValidation } from '../../validation/validator';
import { formatUrl } from '../../utilities';
import useUiStore from '../../store/ui/uiStore';
import useMemberStore from '../../store/member/memberStore';
import ErrorMessages from '../inputGroups/ErrorMessages';
import track from '../../tracking';
import useUmbracoStore from '../../store/umbraco/umbracoStore';
import { isStepEqualToGiftStep } from '../../store/umbraco/helpers';
import { useStyles } from './styles';

type TProps = {
  confirmLabel: string;
  prevLabel: string;
  nextLabel: string;
};

const NavigationButtons = ({ nextLabel, prevLabel, confirmLabel }: TProps) => {
  const { steps, receipt } = useUmbracoStore((state) => ({
    steps: state.stepNodes,
    receipt: state.receiptNode,
  }));
  const { situation, resetMember, lang, leadId, position, marketingConsent, selectedGift } =
    useMemberStore(
      (state) => ({
        situation: state.member.job.situationId,
        lang: state.member.config.languageId,
        leadId: state.member.config.leadId,
        position: state.member.job.positionName,
        marketingConsent: state.member.config.marketingConsent?.allowed || false,
        resetMember: state.resetMember,
        selectedGift: state.member.gift.selectedGift,
      }),
      shallow,
    );
  const { currentStep, updateUi } = useUiStore(
    (state) => ({
      currentStep: state.currentStep,
      updateUi: state.updateUi,
    }),
    shallow,
  );

  const [processing, setProcessing] = useState(false);
  const totalSteps = Object.keys(steps).length;
  const validator = useValidation();
  const navigate = useNavigate();

  const { classes } = useStyles();

  const changeStep = (goTo: number, goBack: boolean) => {
    if (goTo === 0) {
      resetMember();
      navigate(formatUrl('/'), { replace: true });
    } else {
      const indexAdjustedNext = goTo - 1;
      navigate(formatUrl(steps[indexAdjustedNext].slug as string), { replace: true });
      updateUi('currentStep', goTo);
    }
    const indexAdjustedCurrentStep = currentStep - 1;

    if (goBack) {
      track.goBack({
        stepName: steps[indexAdjustedCurrentStep].name as string,
        situation,
        leadership: position,
        language: lang.toLowerCase(),
        leadId,
        gift: selectedGift,
      });
    } else {
      if (isStepEqualToGiftStep(currentStep, steps)) {
        track.giftChosen({
          stepName: steps[indexAdjustedCurrentStep].name as string,
          gift: selectedGift,
          situation,
          leadership: position,
          language: lang.toLowerCase(),
          leadId,
        });
      }

      track.goForward({
        stepName: steps[indexAdjustedCurrentStep + 1].name as string,
        situation,
        leadership: position,
        language: lang.toLowerCase(),
        leadId,
        gift: selectedGift,
      });
    }
  };

  const confirm = () => {
    updateUi('formConfirmed', true);
    updateUi('currentStep', totalSteps + 1);
    if (validator.valid) {
      setProcessing(true);
      service
        .startSignupProcess(useMemberStore.getState().member)
        .then(() => {
          track.completed({
            stepName: 'gennemført',
            situation,
            leadership: position,
            language: lang.toLowerCase(),
            leadId,
            consent: marketingConsent,
          });
          setProcessing(false);
        })
        .catch(() => {
          setProcessing(false);
        });
    }

    navigate(formatUrl(receipt.slug as string), { replace: true });
  };

  return (
    <>
      <Group className={classes.wrapper} mb="xl">
        {currentStep < totalSteps && (
          <Button
            id="btnNext"
            onClick={() => changeStep(currentStep + 1, false)}
            disabled={!validator.valid}
            text={
              currentStep === 1 && selectedGift === skipGiftValue
                ? getParagraphText('declineGiftLabel')
                : nextLabel
            }
          />
        )}

        {currentStep === totalSteps && (
          <Button
            id="btnConfirm"
            variant="cta"
            onClick={() => confirm()}
            disabled={!validator.valid}
            loading={processing}
            text={confirmLabel}
          />
        )}

        <OnlyShow smallerThan="sm">
          <IconButton
            id="btnPrevious"
            variant="outline"
            onClick={() => changeStep(currentStep - 1, true)}
            icon="ChevronLeft"
            circular
          />
        </OnlyShow>
        <OnlyShow largerThan="sm">
          <Button
            id="btnPrevious"
            variant="outline"
            onClick={() => changeStep(currentStep - 1, true)}
            text={prevLabel}
          />
        </OnlyShow>
      </Group>
      {currentStep === totalSteps && <ErrorMessages errors={validator.errors} />}
    </>
  );
};

export default NavigationButtons;
