import { IJob, Job } from '@ingeniorforeningen/member-signup-backend-client';

export interface IJobExtended extends IJob {
  situationName: string;
  positionName: string;
  workplaceName: string;
  countryName: string;
  cityName: string;
  postalDistrictName: string;
}

export class JobExtended extends Job {
  situationName: string;

  positionName: string;

  workplaceName: string;

  countryName: string;

  cityName: string;

  postalDistrictName: string;

  constructor(data: IJobExtended) {
    super(data);
    this.cityId = data.cityId;
    this.situationName = data.situationName;
    this.positionName = data.positionName;
    this.workplaceName = data.workplaceName;
    this.countryName = data.countryName;
    this.cityName = data.cityName;
    this.postalDistrictName = data.postalDistrictName;
  }
}
