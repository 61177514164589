/* eslint-disable max-len */

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TSelectFieldItem } from '@ingeniorforeningen/figurine-core';
import { EducationInstitution } from '@ingeniorforeningen/uidata-client';
import shallow from 'zustand/shallow';
import useMemberStore from 'src/store/member/memberStore';
import { uiDataClient } from './clients';
import { getLevelText } from './helpers';

export const useInstitutions = () => {
  const isStudent = useMemberStore((state) => state.member.education.isStudent);

  return useQuery({
    queryKey: ['institutions'],
    queryFn: async () => uiDataClient.getInstitutions(isStudent),
    select: (data): TSelectFieldItem[] =>
      data
        .map((institution) => {
          // @TODO: This check should not be done here and we should instead recieve clean/correct data.
          // PBI: https://dev.azure.com/ingeniorforeningen/Hverve%20og%20Synlighed/_backlogs/backlog/Hverve%20og%20Synlighed%20Team/Features/?workitem=66683
          if (
            institution.name?.toLowerCase() !== 'andet uddannelsessted' &&
            institution.name?.toLowerCase() !== 'udenlandsk uddannelsessted'
          ) {
            return {
              value: institution.id as string,
              label: institution.name as string,
              levels: institution.educationLevels!,
            };
          }
          return { value: '', label: '' };
        })
        .filter((x) => x.value !== ''),
  });
};

export const useSpecializations = (institutionId: string) => {
  const queryClient = useQueryClient();
  const { isStudent, isPhd } = useMemberStore(
    (state) => ({
      isStudent: state.member.education.isStudent,
      isPhd: state.member.education.isPhd,
    }),
    shallow,
  );

  return useQuery({
    queryKey: ['specializations', institutionId],
    queryFn: async () =>
      uiDataClient.getSpecializations(institutionId, undefined, isStudent, isPhd),
    staleTime: Infinity,
    select: (data): TSelectFieldItem[] =>
      data &&
      data
        .map((specialization) => {
          if (specialization.id && specialization.name) {
            const institutions: undefined | EducationInstitution[] = queryClient.getQueryData([
              'institutions',
            ]);
            const institution = institutions?.find((i) => i.id === institutionId);
            const level = institution?.educationLevels?.find(
              (l) => l.id === specialization.levelId,
            );
            return {
              value: specialization.id,
              label: specialization.name,
              description: level ? getLevelText(level) : undefined,
            };
          }
          return { value: '', label: '' };
        })
        .filter((x) => x.value !== ''),
  });
};
