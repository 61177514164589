import React, { useEffect, useState } from 'react';
import {
  Heading,
  Paper,
  Paragraph,
  ModalButton,
  Html,
  Group,
  Container,
} from '@ingeniorforeningen/figurine-core';
import { getParagraphText } from '../../../data/helpers';
import AkSignupForm from './AkSignupForm';
import useMemberStore from '../../../store/member/memberStore';
import track from '../../../tracking';
import useUmbracoStore from '../../../store/umbraco/umbracoStore';
import { useStyles } from './styles';

const AkSignupBase: React.FC = () => {
  const [status, setStatus] = useState('init');
  const language = useMemberStore((state) => state.member.config.languageId).toLowerCase();
  const akaInfo = useUmbracoStore((state) => state.akaInfo);
  const { classes } = useStyles();

  useEffect(() => {
    track.forms.started({ name: 'akasse' });
  }, []);

  return (
    <Container>
      <Paper p="xl" className={classes.desktopOnlyPaper}>
        <Group direction="column" justify="center">
          {status !== 'done' ? (
            <>
              <Heading type="h2" text={akaInfo.signupHeadline[language]} />
              <Html html={akaInfo.signupDescription[language]} />
              <ModalButton
                modalContent={<Html html={akaInfo.terms[language]} />}
                buttonText={getParagraphText('seeConditions')}
              />

              <Paragraph text={getParagraphText('akasseOtherAkasseLabel')} />
              <AkSignupForm updateStatus={setStatus} />
            </>
          ) : (
            <>
              <Heading type="h2" text={akaInfo.signupSuccessfulHeadline[language]} />
              <Paragraph text={<Html html={akaInfo.signupSuccessfulDescription[language]} />} />
            </>
          )}
          <div />
        </Group>
      </Paper>
    </Container>
  );
};

export default AkSignupBase;
