import { NamedSet } from 'zustand/middleware';
import { TUiStore } from './types';

export const handleUiUpdate = (
  set: NamedSet<TUiStore>,
  property: keyof TUiStore,
  value: unknown,
): void => {
  set((state) => ({
    ...state,
    [property]: value,
  }));
};
